import React, { useState, useEffect } from "react";
import { Row, Container } from "reactstrap";
import { connect } from "react-redux";
import { ModalCollections } from "../../../../modals/modalCollections";
import {
  addCategory,
  getAvailableCategories,
  getSelectedCategories,
  addCategories,
  getSelectedComponentsCurrentConfiguration,
  changeCategoryOrder,
  removeCategory,
  getComponentCollectionImages
} from "../../../../../ducks";
import { addMarginTop } from "../../../../utils/utilities";
import { CustomButton } from "../../home-layout-draggable-columns/custom-button";
import { useTranslation } from "react-i18next";

export const UpdateContentSectionBaseCategories = ({
  title,
  availableCategories,
  currentProduct,
  label,
  onInit,
  currentSelectedConfiguration,
  onChange,
  parentOption,
  storeKey
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(0);

  const done = (params) => {

    console.log("params Image 1", params);
    console.log("products", params.configuration.products);
    console.log("parentOption", parentOption);

    setSelectedItems(params.configuration.products.length);
    onChange(params.label, params.configuration.products, parentOption);
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("currentProduct", currentProduct);
    console.log("parentOption", parentOption);
    setSelectedItems(currentProduct ? currentProduct.length : 0);   
  }, [currentProduct]);

  return (
    <Container style={addMarginTop(title)}>
      <Row>
        <CustomButton
          type="CONTENT"
          onClick={() => setIsOpen(true)}
          label={t("homeModalCollections.title").toUpperCase()}          
          counter={true}
          itemsCounter={selectedItems}
        />
      </Row>
      <ModalCollections
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDone={done}
        label={label}
        availableCategories={availableCategories}
        selectedCategories={currentProduct}
        onInit={onInit}
        currentSelectedConfiguration={currentSelectedConfiguration}
        parentOption={parentOption}
        selectOneItem={true}
        storeKey={storeKey}
      />
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => ({
  availableCategories: getAvailableCategories(state),
  selectedCategories: getSelectedCategories(state),
  currentSelectedConfiguration: getSelectedComponentsCurrentConfiguration(
    state
  ),
  currentProduct: getComponentCollectionImages(state, { imageNumber: ownProps.parentOption.split(' ')[1] }) // Split the parentOption string on space and take the second part (the number)
});

const dispatchToProps = {
  dropCategory: addCategory,
  onInit: addCategories,
  onReArrangeIndex: changeCategoryOrder,
  onRemoveCategory: removeCategory,
};

export const UpdateContentSectionCategories1 = connect(
  mapStateToProps,
  dispatchToProps
)(UpdateContentSectionBaseCategories);
