import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import {
  UpdateContentSection,
  UpdateContentSectionCategories,
} from "./update-content-section";
import { Toggle } from "./toggle";
import { ColorPicker } from "./color-picker";
import { EditableInput } from "./editable-input";
import { OptionSelect } from "./option-select";
import { DropdownSelect } from "./dropdown-select";
import { UpdateImageModal } from "../../../modals/modalImages";
import { UpdateVideoModal } from "../../../modals/modalVideos";
import { UpdateContentSection1 } from "./update-content-section/update-content-product-image1";
import { UpdateContentSection2 } from "./update-content-section/update-content-product-image2";
import { UpdateContentSection3 } from "./update-content-section/update-content-product-image3";
import { UpdateContentSectionCategories1 } from "./update-content-section/update-content-categories-image1";
import { UpdateContentSectionCategories2 } from "./update-content-section/update-content-categories-image2";
import { UpdateContentSectionCategories3 } from "./update-content-section/update-content-categories-image3";
import { mapLabel } from "../../../utils/utilities";
import { IndividualContentButtonsComponents } from "../../../utils/constants";
import { MultilineInput } from "./multiline-input";
import { CustomButton } from "../home-layout-draggable-columns/custom-button";
import { useTranslation } from "react-i18next";

import ModalImageTransition from "../../../modals/ModalImageTransition";

export const ConfSection = ({
  title,
  options,
  label,
  type,
  value,
  externalLink,
  onChange,
  hide,
  userType,
  storeKey,
}) => {

  const { t } = useTranslation();  
  const [openImageTransitionModal, setOpenImageTransitionModal] = useState(
    false
  );

  useEffect(() => {
    console.log("openImageTransitionModal value:", openImageTransitionModal);
  }, [openImageTransitionModal]);

  const [animationValue, setAnimationValue] = useState({});

  const determineAnimationRestriction = (title) => {
    console.log(" title", title);
    switch (title) {
      case "Image Triple 100":
      case "Image Triple 50":
      case "Image Double 50":
      case "Image Double 100":
        return "DOUBLE_TRIPLE";
      case "Image 25":
      case "Image 50":
        return "WIDE";
      default:
        return "NO_RESTRICTION";
    }
  };

  const hasRestrictedAnimation = determineAnimationRestriction(title);

  const handleToggleChange = () => {
    onChange(label, !value);
  };

  const handleButtonClick = () => {
    setOpenImageTransitionModal(true);
    setAnimationValue(value);
  };

  const handleTransitionSelectorApply = (transtion) => {
    console.log("animator transition", label);
    console.log("animator transition transtion", transtion);

    setOpenImageTransitionModal(false);
    onChange("Image Animation", transtion);
  };

  const renderImageContentSection = (l, t, v) => {
    if (l === "Image 1") {
      return (
        <UpdateContentSection1
          label="Update product"
          title={`${t}_${v}`}
          onChange={onChange}
          parentOption={l}
          storeKey={storeKey}
        />
      );
    }
    if (l === "Image 2") {
      return (
        <UpdateContentSection2
          label="Update product"
          title={`${t}_${v}`}
          onChange={onChange}
          parentOption={l}
          storeKey={storeKey}
        />
      );
    }
    if (l === "Image 3") {
      return (
        <UpdateContentSection3
          label="Update product"
          title={`${t}_${v}`}
          onChange={onChange}
          parentOption={l}
          storeKey={storeKey}
        />
      );
    }
  };

  const renderImageContentSectionCategory = (l, t, v) => {
    if (l === "Image 1") {
      return (
        <UpdateContentSectionCategories1
          label="Update category"
          title={`${t}_${v}`}
          onChange={onChange}
          parentOption={l}
          storeKey={storeKey}
        />
      );
    }
    if (l === "Image 2") {
      return (
        <UpdateContentSectionCategories2
          label="Update category"
          title={`${t}_${v}`}
          onChange={onChange}
          parentOption={l}
          storeKey={storeKey}
        />
      );
    }
    if (l === "Image 3") {
      return (
        <UpdateContentSectionCategories3
          label="Update category"
          title={`${t}_${v}`}
          onChange={onChange}
          parentOption={l}
          storeKey={storeKey}
        />
      );
    }
  };

  return ((type === "supermodal" || type === "supermodalCategories") &&
    IndividualContentButtonsComponents.includes(title)) ||
    hide ? null : (
    <Row className="confsection">
      <Col
        className={
          type === "toggle" && options?.length && options[0].type === "input"
            ? "toggle-with-input"
            : ""
        }
      >
        {type === "supermodal" &&
          !IndividualContentButtonsComponents.includes(title) && (
            <Row>
              <UpdateContentSection
                label={label}
                title={title}
                onChange={onChange}
                storeKey={storeKey}
              />
            </Row>
          )}

        <Row style={type === "toggle" ? { alignItems: "center" } : {}}>
          {type !== "supermodal" &&
            type !== "supermodalCategories" &&
            !label.includes("Image 1") &&
            !label.includes("Image 2") &&
            !label.includes("Image 3") &&
            type !== "image"  &&
            type !== "video" && (
              <Col className="config-label parent-label">
                {label.toLowerCase() === "ocultar titulo"
                  ? "title"
                  : mapLabel(label)}
                :
              </Col>
            )}

          {type === "toggle" && (
            <Col className="nopadding d-flex justify-content-end">
              <Toggle
                label={label}
                savedValue={value}
                onChange={handleToggleChange}
              />
            </Col>
          )}
          {type === "input" && (
            <Col className="nopadding d-flex justify-content-end">
              <EditableInput
                label={label}
                savedValue={value}
                onChange={onChange}
              />
            </Col>
          )}
          {type === "multiline-input" && (
            <Col className="nopadding d-flex justify-content-end">
              <MultilineInput
                label={label}
                savedValue={value}
                onChange={onChange}
              />
            </Col>
          )}
          {type === "image" && (
            <UpdateImageModal
              savedValue={value}
              label={label}
              title={title}
              onChange={onChange}
              storeKey={storeKey}
              userType={userType}
            />
          )}
           {type === "video" && (
            <UpdateVideoModal
              savedValue={value}
              label={label}
              title={title}
              onChange={onChange}
              storeKey={storeKey}
              userType={userType}
            />
          )}
          {type === "animator" && (
            <div
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                marginRigth: "20px",
                width: "90%",
              }}
            >
              <CustomButton
                customWidth="108%"
                customMarginLeft="-15px"
                type="CONTENT"
                label={t("homeModalRight.selectAnimation")}
                onClick={handleButtonClick}
              />
            </div>
          )}
          {type === "colorPicker" && (
            <ColorPicker label={label} savedValue={value} onChange={onChange} />
          )}

          {type === "option" &&
            !title.includes("Image") &&
            !title.includes("News") &&
            !title.includes("Botones") &&
            !title.includes("Video") &&  (
              <Col className="nopadding d-flex justify-content-end">
                <OptionSelect
                  label={label}
                  savedValue={value}
                  onChange={onChange}
                  optionsAmount={
                    title.includes("Slider productos destacados") ? 3 : 2
                  }
                />
              </Col>
            )}
          {/* Cambiando News de dropdown a option*/}
          {type === "option" && (
            <>
              <OptionSelect
                label={label}
                savedValue={value}
                onChange={onChange}
                availableOptions={options}
              />
              {label === "Action" && value == 0 && (
                <UpdateContentSection
                  label="Update product"
                  title={`${title}_${value}`}
                  onChange={onChange}
                  storeKey={storeKey}
                />
              )}
              {label === "Action" && value == 1 && (
                <UpdateContentSectionCategories
                  label="Update category"
                  title={`${title}_${value}`}
                  onChange={onChange}
                  storeKey={storeKey}
                />
              )}
              {label === "Action" && value == 2 && (
                <div className="content-action-link">
                  <Col
                    className=" nopadding justify-content-end"
                    style={{
                      paddingLeft: "14px",
                      paddingTop: "25px",
                      paddingRight: "15px",
                    }}
                  >
                    <EditableInput
                      placeholder={"Click to add external link"}
                      label={label}
                      savedValue={externalLink}
                      onChange={onChange}
                      parentOption={label}
                    />
                  </Col>
                </div>
              )}
            </>
          )}
          {type === "dropdown" && (
            <>
              <DropdownSelect
                style={
                  label == "Line Spacing"
                    ? { width: "64px", marginRight: "-12px" }
                    : {}
                }
                label={label}
                savedValue={value}
                onChange={onChange}
                availableOptions={options}
              />
              {label === "Action" && value == 0 && (
                <UpdateContentSection
                  label="Update product"
                  title={`${title}_${value}`}
                  onChange={onChange}
                  storeKey={storeKey}
                />
              )}
              {label === "Action" && value == 1 && (
                <UpdateContentSectionCategories
                  label="Update category"
                  title={`${title}_${value}`}
                  onChange={onChange}
                  storeKey={storeKey}
                />
              )}
              {label === "Action" && value == 2 && (
                <div className="content-action-link">
                  <Col
                    className=" nopadding justify-content-end"
                    style={{
                      paddingLeft: "14px",
                      paddingTop: "25px",
                      paddingRight: "15px",
                    }}
                  >
                    <EditableInput
                      placeholder={"Click to add external link"}
                      label={label}
                      savedValue={externalLink}
                      onChange={onChange}
                      parentOption={label}
                    />
                  </Col>
                </div>
              )}
            </>
          )}
        </Row>
        {(type === "toggle" || type === "template") && value && (
          <div className={getClassName(label)}>
            {console.log("LABEL HERE", label)}
            {options &&
              options.length > 0 &&
              options.map(
                ({ label: childLabel, type, value, options, hide }, index) => (
                  <Row className="option" key={index}>
                    {type !== "input" && !hide && (
                      <Col
                        className="config-label"
                        style={{ marginLeft: "45px" }}
                      >
                        {(mapLabel(childLabel) !== "Pager Type" &&
                          mapLabel(childLabel) !== "High Light Alignment") ||
                        (mapLabel(childLabel) !== "Main Color" &&
                          title == "Producto Destacado")
                          ? mapLabel(childLabel)
                          : ""}
                      </Col>
                    )}
                    {type === "toggle" && (
                      <Col className="nopadding d-flex justify-content-end">
                        <Toggle
                          label={childLabel}
                          savedValue={value}
                          onChange={onChange}
                          parentOption={label}
                        />
                      </Col>
                    )}
                    {type === "colorPicker" && (
                      <ColorPicker
                        label={childLabel}
                        savedValue={value}
                        onChange={onChange}
                        parentOption={label}
                      />
                    )}
                    {type === "dropdown" && !hide && (
                      <div
                        style={
                          childLabel === "Time Delay"
                            ? { width: "64px", marginRight: "-15px" }
                            : {}
                        }
                      >
                        <DropdownSelect
                          style={
                            label === "Spacing"
                              ? { width: "64px", marginRight: "-12px" }
                              : childLabel === "Time Delay"
                              ? { width: "60px" }
                              : {}
                          }
                          label={childLabel}
                          savedValue={value}
                          onChange={onChange}
                          parentOption={label}
                          availableOptions={options}
                        />
                      </div>
                    )}
                    {type === "option" && (
                      <OptionSelect
                        label={childLabel}
                        savedValue={value}
                        onChange={onChange}
                        parentOption={label}
                        optionsAmount={
                          title.includes("Slider productos destacados") ? 3 : 2
                        }
                        availableOptions={options}
                      />
                    )}
                  </Row>
                )
              )}
          </div>
        )}
        {type === "option" &&
          !title.includes("Image") &&
          !title.includes("News") &&
          !title.includes("Botones") &&
          !title.includes("Video") && (
            <div className="optionsContainer">
              {options &&
                options.length > 0 &&
                options.map(
                  ({ label: childLabel, type, value, options }, index) => (
                    <Row className="option" key={index}>
                      {type !== "input" && type !== "option" && (
                        <Col style={{ marginLeft: "30px" }}>{childLabel}</Col>
                      )}
                      {type === "toggle" && (
                        <Col style={{ marginLeft: "100px" }}>
                          <Toggle
                            label={childLabel}
                            savedValue={value}
                            onChange={onChange}
                            parentOption={label}
                          />
                        </Col>
                      )}
                      {type === "colorPicker" && (
                        <Col style={{ marginLeft: "100px" }}>
                          <ColorPicker
                            label={childLabel}
                            savedValue={value}
                            onChange={onChange}
                            parentOption={label}
                          />
                        </Col>
                      )}
                      {type === "dropdown" && (
                        <Col className="d-flex justify-content-center">
                          <DropdownSelect
                            style={{ width: "80px" }}
                            label={childLabel}
                            savedValue={value}
                            onChange={onChange}
                            parentOption={label}
                            availableOptions={options}
                          />
                        </Col>
                      )}
                    </Row>
                  )
                )}
            </div>
          )}
        {type === "toggle" && (
          <div className="optionsContainer">
            {options &&
              options.length > 0 &&
              options.map(
                ({ label: childLabel, type, value: inputValue }, index) => (
                  <Row className="option" key={index}>
                    {type === "input" && (
                      <Col>
                        <EditableInput
                          label={childLabel}
                          savedValue={inputValue}
                          onChange={onChange}
                          parentOption={label}
                          disabled={value}
                        />
                      </Col>
                    )}
                  </Row>
                )
              )}
          </div>
        )}

        {type === "image" && (
          <div className="optionsContainer">
            {!title.includes("News") &&
              !title.includes("Image Double") &&
              !title.includes("Image Triple") && (
                <div
                  style={{
                    borderBottom: "1px solid #dee2e6",
                    marginTop: "15px",
                    marginBottom: "15px",
                    paddingBottom: "20px",
                  }}
                ></div>
              )}
            {options &&
              options.length > 0 &&
              options.map(
                (
                  {
                    label: childLabel,
                    type,
                    options: childOptions,
                    value: childValue,
                    externalLink,
                  },
                  index
                ) => {
                  console.log("childValue:", childValue);
                  console.log("childOptions:", childOptions);
                  console.log("childLabel:", childLabel);
                  console.log("type:", type);

                  return (
                    <Row className="option" key={index}>
                      {type === "option" && (
                        <>
                          <Col
                            className="config-label"
                            style={{ marginLeft: "15px" }}
                          >
                            {"ACTION:"}
                          </Col>
                          <Col className="nopadding d-flex justify-content-end">
                            <OptionSelect
                              label={childLabel}
                              savedValue={childValue}
                              onChange={onChange}
                              availableOptions={childOptions}
                              parentOption={label}
                            />
                          </Col>
                          {childLabel === "Action" &&
                            childValue == 0 &&
                            renderImageContentSection(
                              label,
                              title,
                              value,
                              childLabel
                            )}
                          {childLabel === "Action" &&
                            childValue == 1 &&
                            renderImageContentSectionCategory(
                              label,
                              title,
                              value,
                              childLabel
                            )}
                          {childLabel === "Action" && childValue == 2 && (
                            <div className="content-action-link">
                              <Col
                                className=" nopadding justify-content-end"
                                style={{
                                  paddingLeft: "14px",
                                  paddingTop: "25px",
                                  paddingRight: "15px",
                                }}
                              >
                                <EditableInput
                                  placeholder={"Click to add external link"}
                                  label={childLabel}
                                  savedValue={externalLink}
                                  onChange={onChange}
                                  parentOption={label}
                                />
                              </Col>
                            </div>
                          )}
                        </>
                      )}
                    </Row>
                  );
                }
              )}
          </div>
        )}

        {type === "supermodalCategories" &&
          !IndividualContentButtonsComponents.includes(title) && (
            <Row>
              <UpdateContentSectionCategories
                label={label}
                title={title}
                onChange={onChange}
                storeKey={storeKey}
              />
            </Row>
          )}
      </Col>
      {openImageTransitionModal && (
        <ModalImageTransition
          isOpen={openImageTransitionModal}
          onClose={() => setOpenImageTransitionModal(false)}
          onApply={handleTransitionSelectorApply}
          currentAnimation={animationValue}
          hasRestrictedAnimation={hasRestrictedAnimation}
        />
      )}
    </Row>
  );
};

const getClassName = (label) => {
  switch (label) {
    case "Scroll":
      return "optionsContainerScroll";
    case "Top Configuration":
      return "optionsContainerTopConfig";
    case "temp":
      return "optionsContainerTemp";
    default:
      return "optionsContainer";
  }
};
