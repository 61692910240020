/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButtonIcon from "../../common/RadioButtonIcon";
import BoxText from "../../common/BoxText";
import ListStyle from "./ListStyle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchCurrentConfigTheme,
  fetchCheckoutListData,
  updateCheckoutListData,
} from "../../../api";

function CartCheckoutListStyle({ storeKey }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState();
  const [block, setBlock] = useState("plain");
  const [templateID, setTemplateID] = useState();

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (!templateID) return; 
      fetchData();
  }, [templateID]);

  const getCurrentConfigTheme = () => {
    setIsLoading(true);

    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);
    console.log("storeKey", storeKey);

    if (!templateID) {
      return;
    }
    
    // API
    fetchCheckoutListData(storeKey, templateID)
      .then((response) => {
        const data = response.data;
        const theme = data.theme;

        console.log("theme", theme);

        if (theme !== undefined && theme !== null) {
          setIsSelected(Number(theme));
          setBlock(Number(theme) == 0 ? "plain" : "bubbles");
        }
        
        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching cart checkout data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  const updateConfig = async () => {
    setIsSaving(true);

    if (!templateID || !storeKey) {
      console.error("Missing storeKey or templateID.");
      return;
    }
  
    // Validate that isSelected is a valid number (0 or 1, etc.)
    if (isSelected === null || typeof isSelected === "undefined") {
      console.error("Theme (isSelected) is missing.");
      return;
    }

    // API
    updateCheckoutListData(
      storeKey,
      templateID,
      isSelected
    )
      .then((response) => {
        console.log("response", response);
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateCheckoutListData:", error);
      });
  };

  const checkedChange = (e) => {
    setBlock(e.target.name);
    setIsSelected(Number(e.target.value));
  };

  return (
    <section className="--checkoutList productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.loading")}</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.saving")}</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">{t("checkout.title")}:</h2>
          <nav
            className="componentNav"
            style={{  marginTop: "10px" }}
          >
            <ul className="componentList">
              <li className="itemList active">
                LIST STYLE
                <BoxText handleSaveButton={updateConfig}>
                {t("checkout.description")}
                </BoxText>
              </li>
              <li className="itemList">
                <Link to="/qty-stepper" style={{ color: "#6D7A8B" }}>
                QUANTITY STEPPER
                </Link>
              </li>
              <li className="itemList">
                <Link to="/bottom" style={{ color: "#6D7A8B" }}>
                BOTTOM
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row">
          <h2 className="title">Themes:</h2>
          <div className="col settings radios">
            <RadioButtonIcon
              label="PLAIN WITH LINES"
              id="plain"
              value={0}
              onChange={checkedChange}
              check={isSelected}
              title="Plain with Lines"
              description=""
              video="./sidevideos/checkoutList1.mp4"  
            />
            <RadioButtonIcon
              label="SQUARED BUBBLES"
              id="bubbles"
              value={1}
              onChange={checkedChange}
              check={isSelected}
              title="Squared Bubbles"
              description=""
              video="./sidevideos/checkoutList2.mp4"  
            />
          </div>
        </div>
      </div>
      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <ListStyle theme={block} />
      </div>
    </section>
  );
}

export default CartCheckoutListStyle;
