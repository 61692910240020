/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import BoxText from "../../common/BoxText";
import RadioButton from "../../common/RadioButton";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import LoaderAnimattion from "./LoaderAnimation";
import { EditableInput } from "../main/configurator/editable-input";
import { MultilineInput } from "../main/configurator/multiline-input";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchCurrentConfigTheme,
  fetchGlobalLoaderData,
  updateGlobalLoaderData,
  addGlobalLottieLoader
} from "../../../api";


function Loaders({ storeKey }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState(null);
  const [customLoaderJson, setCustomLoaderJson] = useState(null);
  const [customLoaderName, setCustomLoaderName] = useState(null);
  const [savedCustomLoaders, setSavedCustomLoaders] = useState([]);
  const [lottieCustomIndex, setLottieCustomIndex] = useState(null);
  const [isValidCustomLoader, setIsValidCustomLoader] = useState(false);
  const [templateID, setTemplateID] = useState();
 

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  useEffect(() => {
    if (!isSelected && savedCustomLoaders.length && lottieCustomIndex >= 0 && lottieCustomIndex < savedCustomLoaders.length) {
      const selected = savedCustomLoaders[lottieCustomIndex];
      if (selected) {
        setIsSelected(selected.name);
        setCustomLoaderJson(selected.data);
      } else {
        console.warn(`No saved custom loader found at index: ${lottieCustomIndex}`);
        // Handle the case here when no saved custom loader is found at the given index
      }
    }
  }, [savedCustomLoaders, lottieCustomIndex]);

  const getCurrentConfigTheme = () => {
    setIsLoading(true);
    
    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

    // API
    fetchGlobalLoaderData(storeKey, templateID)
    .then((response) => {
      // Here's the change
      const data = response.data;
      const loaderData = data.loader;
      const customLoadersData = data.custom;

      if (customLoadersData?.lottieCustom) {
        // Filter out invalid items
        const lottieCustom = customLoadersData.lottieCustom.filter((item) => {
          return item && item.data && item.name;
        });

        setSavedCustomLoaders(lottieCustom);
      }

      if (loaderData?.lottieCustomIndex) {
        setLottieCustomIndex(loaderData.lottieCustomIndex - 1);
      }
      if (loaderData?.loaderId) {
        setIsSelected(loaderData.loaderId);
      }
      
      setIsLoading(false); // Set loading state to false after data is loaded
    })
    .catch((error) => {
      console.error("Error fetching navBar data:", error);
      setIsLoading(false); // Set loading state to false after data is loaded
    });

  };


  const updateConfig = async () => {
    setIsSaving(true);

    let index = -1;
        index = savedCustomLoaders.findIndex(
            (loader) => loader && loader.name === isSelected
        );
  
    const dataKey = index + 1 ;

    // API
    updateGlobalLoaderData(
      storeKey,
      templateID,
      dataKey
    )
      .then((response) => {
        console.log("response", response);
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateGlobalLoaderData:", error);
      });

  };


  const updateConfigWithCustomLoaders = async () => {
    if (customLoaderName && customLoaderJson) {
      setIsSaving(true);
      
      const currentIndex = savedCustomLoaders.length;

      // API
      addGlobalLottieLoader(
        storeKey,
        templateID,
        currentIndex,
        customLoaderJson,
        customLoaderName
      )
        .then((response) => {
          console.log("response", response);
          setIsSaving(false);
        })
        .catch((error) => {
          console.error("Error updateGlobalLoaderData:", error);
        });
    }

  };
  
  const checkedChange = (e) => {
    const value = e.target.value;
    
    setCustomLoaderJson(null)
    if (isNaN(value)) {
      if (savedCustomLoaders && savedCustomLoaders.length > 0) {
        const customLoader = savedCustomLoaders.find(
          (loaders) => loaders && loaders.name && loaders.name === value
        );
        setCustomLoaderJson(customLoader.data);
      }    
    }
  
    setIsSelected(value);

  };

  const handleCustomLoaderJsonChange = (value) => {    
    if (isValidJson(value)) {
      setCustomLoaderJson(value);
      setIsValidCustomLoader(true);
    } else {
      setCustomLoaderJson(null);
      setIsValidCustomLoader(false);
    }  
  };

  const handleCustomLoaderNameChange = (label, value, parentOption) => {
    if (value && value.length >= 5) {
      setCustomLoaderName(value);
    }else{
      setCustomLoaderName(null);
    }
  };

  const handleSaveCustomLoader = async () => {
    const temp = savedCustomLoaders;
    temp.push({
      name: customLoaderName.toUpperCase(),
      data: customLoaderJson,
    });
    setSavedCustomLoaders(temp);
    setCustomLoaderName(null);
    setIsSelected(customLoaderName.toUpperCase());

    // save in firebase
    updateConfigWithCustomLoaders();
  };

  const handleTestCustomLoader = () => {
    setIsValidCustomLoader(true);
  };

  const isValidJson = (json) => {
    if (!json) {
      return false;
    }
    try {
      const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
  
      const requiredFields = {
        "v": "string",
        "w": "number",
        "h": "number",
        "fr": "number",
        "ip": "number",
        "op": "number",
        "layers": "object"
      };
  
      // Check for required fields and their types
      for (const [field, type] of Object.entries(requiredFields)) {
        if (!(field in parsedJson) || typeof parsedJson[field] !== type) {
          return false;
        }
      }
  
      // Additional validation for 'layers' to ensure it is a non-empty array
      if (!Array.isArray(parsedJson.layers) || parsedJson.layers.length === 0) {
        return false;
      }
  
      // Optional: Further validation of the contents of the 'layers' array
      for (const layer of parsedJson.layers) {
        if (typeof layer !== 'object' || !("ty" in layer)) {
          return false;
        }
      }
  
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <section className="--globalLoaders productList row">
    {isLoading && (
      <div className="loading-overlay">
        <div className="loading-content">
          <img src={LoadingImage} alt="Loading" />
        </div>
        <div className="loading-text">{t("common.loading")}</div>
      </div>
    )}
    {isSaving && (
      <div className="loading-overlay">
        <div className="loading-content">
          <img src={LoadingImage} alt="Loading" />
        </div>
        <div className="loading-text">{t("common.saving")}</div>
      </div>
    )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">{t("global.title")}:</h2>
          <nav className="componentNav" style={{  marginTop: "10px" }}>
            <ul className="componentList">
            <li className="itemList">
                <Link to="/colors" style={{ color: "#6D7A8B" }}>
                GLOBAL COLORS
                </Link>
              </li>
              <li className="itemList active">
                LOADERS
                <BoxText handleSaveButton={updateConfig}>
                {t("global.descriptionLoader")} <a href="https://lottiefiles.com/category/loading/animations" target="_blank" rel="noopener noreferrer">
                {t("global.linkLoader")}
            </a>
          </BoxText>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row"  style={{ width: "125%" }}>
          <h2 className="title">Loaders:</h2>
          <div
            className="col settings radios"
            style={{ overflowY: "scroll", height: "475px", overflowX: "hidden" }}
          >
            <RadioButton
              label="ADD NEW LOADER"
              id="custom"
              value={0}
              onChange={checkedChange}
              check={isSelected === null ? 0 : isSelected}
            />
    
            {savedCustomLoaders.map((c, index) => {
              if (c.name && c.name.toUpperCase()) {
                return (
                  <RadioButton
                    key={index}
                    label={c.name.toUpperCase()}
                    id={c.name}
                    value={c.name}
                    onChange={checkedChange}
                    check={isSelected}
                  />
                );
              }
            })}
          </div>
          <div className="col-sm settings radioCustom --line">
            {isSelected == 0 && (
              <div className="newLoaderContainer">
                <h2 className="title"  style={{ marginLeft: "-10px", marginTop: "-20px" }}>{t("global.addLoader")}:</h2>
                <div style={{ marginLeft: "-10px", marginTop: "-10px" }}>{t("global.loaderName")}</div>
                <EditableInput
                  placeholder="NEW LOADER"
                  onChange={handleCustomLoaderNameChange}
                  isSearch
                />
                <MultilineInput
                  placeholder="JSON"
                  onChange={handleCustomLoaderJsonChange}
                  isSearch
                />
               {isValidJson(customLoaderJson) && customLoaderName ? (
                  <div className="buttonContainer row">
                    <button
                      className="buttonComponent upload"
                      onClick={handleTestCustomLoader}
                    >
                      TEST ANIMATION
                    </button>
                    <button
                      className="buttonComponent upload"
                      onClick={handleSaveCustomLoader}
                    >
                      SAVE
                    </button>
                  </div>
                ) : (
                  <div className="buttonContainer row">
                    <button
                      disabled={!customLoaderJson}
                      className="buttonComponent animation"
                    >
                      TEST ANIMATION
                    </button>
                    <button
                      disabled={!isValidCustomLoader}
                      className="buttonComponent default"
                    >
                      SAVE
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
  className="img__edit col-md2"
  style={{ backgroundImage: `url(${Background})` }}
>

    <LoaderAnimattion
      selectedLoader={isSelected}
      customLoaderJson={customLoaderJson}
    />

</div>
    </section>
  );
}

export default Loaders;
