import React, { useState, useEffect } from 'react';
import { Modal, ButtonGroup } from "reactstrap";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import { fetchForAllProducts } from "../../api";
import { useTranslation } from "react-i18next";

import  Product  from "./items/Product.jsx"

import "./items/modalCSS.css"

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';


export const ModalProducts = ({ 
  isOpen,
  onClose,
  onDone,
  availableProducts,
  selectedProducts,
  label,
  isModalNotification,
  selectOneItem,
  storeKey
}) => {
  const { t } = useTranslation();

  const filterProducts = () => {
    console.log("selectedProducts", selectedProducts);
    const selectedProductList = selectedProducts ?? [];
    const filteredAvailableProducts = availableProducts.filter((ap) => {
      // returns true if ap is not found in selectedProducts
      return !selectedProductList.some((sp) => {
        if (sp == null || ap == null) return false;
        return sp.id === ap.id;
      });
    });

    setProducts(filteredAvailableProducts);
    
  };

  useEffect(() => {
    if (isModalNotification == true) {
      fetchData();
    }
  }, [isModalNotification]);

  useEffect(() => {
    setCartItems(selectedProducts ?? []);
    filterProducts();
  }, [selectedProducts]);

  useEffect(() => {
    filterProducts();
  }, [availableProducts]);

  // Fetch Products for Notification Modal
  const fetchData = async () => {
    console.log("storeKey", storeKey)
    if (storeKey) {
      const response = await fetchForAllProducts({ storeKey: storeKey });
      const filteredProducts = response.data.filter((p) => {
        const foundIndex = selectedProducts.findIndex((sp) => sp.id === p.id);
        return foundIndex === -1; // Exclude products found in selectedProducts
      });
      console.log("filteredProducts", filteredProducts);
      setProducts(filteredProducts);
    }
  };

  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  const handleAddToCart = (product) => {
    console.log("selectOneItem", selectOneItem);
    if (!selectOneItem || (selectOneItem && cartItems.length === 0)) {
      console.log("handleAddToCart", product);
      const foundProduct = products.find((p) => p.id === product.id);
      if (foundProduct) {
        setCartItems([...cartItems, foundProduct]);
        setProducts(products.filter((p) => p.id !== product.id));
      }
    }
  };

  const handleRemoveFromCart = (productId) => {
    console.log("handleRemoveFromCart", productId);
    const updatedCartItems = cartItems.filter((item) => item.id !== productId);
    setCartItems(updatedCartItems);

    const removedProduct = cartItems.find((item) => item.id === productId);
    if (removedProduct) {
      console.log("removedProduct", removedProduct);
      setProducts([...products, removedProduct]);
    }
  };

  const handleAddAllToCart = () => {
    setCartItems([...cartItems, ...products]);
    setProducts([]);
  };

  const handleRemoveAllFromCart = () => {
    setProducts([...products, ...cartItems]);
    setCartItems([]);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const secondHandleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setCartItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="transitionSelector preview previewModal isOpen"
      size="xl"
    >
      

      <section>
        <div class="container-modal-product">
          <div class="left_container">
            <div class="product_container">
              <h2 class="">{t("homeModalProducts.title")}</h2>
              <div class="products">
                <div class="product_list">
                  {products &&
                    products.map((product) => (
                      <Product
                        key={product.id}
                        id={product.id}
                        name={product.name}
                        price={product.price}
                        image={product.images}
                        onAddToCart={handleAddToCart}
                        onRemoveFromCart={null} // Disable remove button in the products list
                      ></Product>
                    ))}
                </div>
              </div>

              {/* {products.length > 0 && ( */}
              <div class="bottom_product_container">
                <h3 class="product_found">
                  {products ? products.length : 0} {t("homeModalProducts.productsFound")}
                </h3>
                <h3 class="addToCart" onClick={handleAddAllToCart}>
                {t("homeModalProducts.addThemAll")}
                </h3>
              </div>
              {/* )} */}
            </div>
          </div>
          <div class="right_container">
            <div class="item_container">
              <div class="right_container_btn">
                <ButtonGroup>
                  <CustomButton
                    type="CANCEL"
                    label={t("homeModalProducts.buttonCancel")}
                    onClick={onClose}
                  />
                  <button
                type="button"
                className="close__button custom-button save-modal-button"
                onClick={() => {
                  console.log("cartItems: ", cartItems);
                  onDone({
                    label,
                    configuration: {
                      type: "supermodal",
                      products: cartItems,
                    },
                  });
                }}
              >
                {t("homeModalProducts.buttonUpdate")} 
              </button>
                </ButtonGroup>
              </div>

              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={secondHandleDragEnd}
              >
                <SortableContext
                  items={cartItems || []}
                  strategy={rectSortingStrategy}
                >
                  <div class="products">
                    <div class="product_list">
                      {cartItems &&
                        cartItems.map((item) => {
                          console.log("Validating item:", item);
                          // Check if item has valid id, name, price, and images properties
                          if (
                            item &&
                            typeof item === 'object' &&
                            'id' in item &&
                            'name' in item &&
                            'price' in item &&
                            'images' in item
                          ) {
                            console.log("Item passed validation:", item);
                            return (
                              <Product
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                price={item.price}
                                image={item.images}
                                onAddToCart={null}
                                onRemoveFromCart={() =>
                                  handleRemoveFromCart(item.id)
                                }
                              ></Product>
                            );
                          } else {
                            // Handle validation error, e.g., log an error message
                            console.error("Invalid item in cartItems. Missing required properties:", {
                              hasItem: !!item,
                              hasId: item && 'id' in item,
                              hasName: item && 'name' in item,
                              hasPrice: item && 'price' in item,
                              hasImages: item && 'images' in item,
                              itemType: item && typeof item
                            });
                            return null; // Skip rendering invalid item
                          }
                        })}
                    </div>
                  </div>
                </SortableContext>
              </DndContext>

              <div class="bottom_product_container">
                <h3 class="product_found">
                  {cartItems ? cartItems.length : 0} {t("homeModalProducts.productsFound")}
                </h3>
                <h3 class="removeFromCart" onClick={handleRemoveAllFromCart}>
                {t("homeModalProducts.buttonClear")}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ModalProducts;

