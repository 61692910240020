import React, { useState, useEffect } from "react";
import { Row, Container } from "reactstrap";
import { connect } from "react-redux";
import { ModalProducts } from "../../../../modals/modalProducts";
import {
  addProduct2,
  getAvailableProducts2,
  getSelectedProducts2,
  addProducts2,
  getSelectedComponentsCurrentConfiguration,
  changeProductOrder2,
  removeProduct2,
  getComponentProductsImages
} from "../../../../../ducks";
import { addMarginTop } from "../../../../utils/utilities";
import { CustomButton } from "../../home-layout-draggable-columns/custom-button";
import { useTranslation } from "react-i18next";

export const UpdateContentSectionBase = ({
  title,
  availableProducts,
  label,
  onInit,
  currentSelectedConfiguration,
  currentProduct,
  onChange,
  parentOption,
  storeKey
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(0);

  const done = (params) => {

    console.log("params Image 1", params);
    console.log("products", params.configuration.products);
    console.log("parentOption", parentOption);

    setSelectedItems(params.configuration.products.length);
    onChange(params.label, params.configuration.products, parentOption);
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("parentOption", parentOption);
    console.log("label", label);    
    console.log("currentProduct", currentProduct);
    setSelectedItems(currentProduct ? currentProduct.length : 0);   
  }, [currentProduct]);

  return (
    <Container style={addMarginTop(title)}>
      <Row>
        <CustomButton
          type="CONTENT"
          onClick={() => setIsOpen(true)}
          label={t("homeModalProducts.title").toUpperCase()}          
          counter={true}
          itemsCounter={selectedItems}
        />
      </Row>
      <ModalProducts
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDone={done}
        label={label}
        availableProducts={availableProducts}
        selectedProducts={currentProduct}
        onInit={onInit}
        currentSelectedConfiguration={currentSelectedConfiguration}
        parentOption={parentOption}
        selectOneItem={true}
        storeKey={storeKey}
      />
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => ({
  availableProducts: getAvailableProducts2(state),
  selectedProducts: getSelectedProducts2(state),
  currentSelectedConfiguration: getSelectedComponentsCurrentConfiguration(state),
  currentProduct: getComponentProductsImages(state, { imageNumber: ownProps.parentOption.split(' ')[1] }) // Split the parentOption string on space and take the second part (the number)
});

const dispatchToProps = {
  dropProduct: addProduct2,
  onInit: addProducts2,
  onReArrangeIndex: changeProductOrder2,
  onRemoveProduct: removeProduct2,
};

export const UpdateContentSection2 = connect(
  mapStateToProps,
  dispatchToProps
)(UpdateContentSectionBase);
