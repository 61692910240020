/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import BoxText from "../../common/BoxText";
import GlobalColorOptions, { options } from "./GlobalColorOptions";
import ColorV2 from "./ColorsV2";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchCurrentConfigTheme,
  fetchGlobalColorData,
  updateGlobalColorData,
} from "../../../api";

function GlobalColors({ storeKey }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [color1, setColor1] = useState();
  const [color2, setColor2] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [templateID, setTemplateID] = useState();
 

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  const getCurrentConfigTheme = () => {
    setIsLoading(true);
    
    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

    // API
    fetchGlobalColorData(storeKey, templateID)
      .then((response) => {
        // Here's the change
        const data = response.data;
        const colorData = data.color;

        if (colorData) {
          setColor1(colorData.mainColor);
          setColor2(colorData.secondaryColor);
          setSelectedColor(
            getOptionFromColor(colorData.mainColor, colorData.secondaryColor)
          );
        }

        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching navBar data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };


  const updateConfig = async () => {
    setIsSaving(true);

    // API
    updateGlobalColorData(
      storeKey,
      templateID,
      color1,
      color2     
    )
      .then((response) => {
        console.log("response", response);
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateCheckoutBottomData:", error);
      });
  };

  const handleOnSelectCustomColor1 = (e) => {
    setColor1(e.target.value);
  };
  const handleOnSelectCustomColor2 = (e) => {
    setColor2(e.target.value);
  };

  const handleOnSelectColor = (color1, color2, selectedColor) => {
    setColor1(color1);
    setColor2(color2);
    setSelectedColor(selectedColor);
  };

  const handleResetPalette = () => {
    const currentPallete = options[selectedColor];
    if (currentPallete) {
      handleOnSelectColor(
        currentPallete.color1,
        currentPallete.color2,
        selectedColor
      );
    }
  };

  return (
    <section className="--globalStyle productList row">
      {isLoading && (
      <div className="loading-overlay">
        <div className="loading-content">
          <img src={LoadingImage} alt="Loading" />
        </div>
        <div className="loading-text">{t("common.loading")}</div>
      </div>
    )}
    {isSaving && (
      <div className="loading-overlay">
        <div className="loading-content">
          <img src={LoadingImage} alt="Loading" />
        </div>
        <div className="loading-text">{t("common.saving")}</div>
      </div>
    )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">{t("global.title")}:</h2>
          <nav className="componentNav" style={{  marginTop: "10px" }}>
            <ul className="componentList">
              <li className="itemList active">
                GLOBAL COLORS
                <BoxText handleSaveButton={updateConfig}>
                {t("global.description")}
                </BoxText>
              </li>
              <li className="itemList">
                <Link to="/loaders" style={{ color: "#6D7A8B" }}>
                LOADERS
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row"  style={{ position: "relative", width: "120%"}}>
          <h2 className="title">Color themes:</h2>
          <GlobalColorOptions
            onSelectColor={handleOnSelectColor}
            selected={selectedColor}
          />
          <div className="row settings radioCustom --line" style={{ position: "relative", top: "10px", left: "30px" }}>
            {}
            <h2 className="title"  style={{ position: "relative", top: "-2px", left: "-50px" }}>Change Colors:</h2>
            <div className="row themeSetting" style={{ position: "relative", top: "10px" }}>   
              THEME COLOR 1 · BASEMENT
              <div className="contentColors" style={{ position: "absolute", top: "35px", left: "2px" }}>
                <input
                  type="text"
                  name="textColors"
                  className="textColor"
                  value={color1}
                  onChange={handleOnSelectCustomColor1}
                />
                <input
                  type="color"
                  name="colors"
                  className="pickColor"
                  value={color1}
                  onChange={handleOnSelectCustomColor1}
                />
              </div>
            </div>            
            <div className="row themeSetting" style={{ position: "relative", top: "55px" }}>            
              THEME COLOR 2 · BRIGHTER
              <div className="contentColors"  style={{ position: "absolute", top: "27px", left: "2px" }}>
                <input
                  type="text"
                  name="textColors"
                  className="textColor"
                  value={color2}
                  onChange={handleOnSelectCustomColor2}
                />
                <input
                  type="color"
                  name="colors"
                  className="pickColor"
                  value={color2}
                  onChange={handleOnSelectCustomColor2}
                />
              </div>
            </div>
            <button
              className="buttonComponent default"
              style={{ position: "relative", top: "110px", left: "-16px", width: "185px" }}
              onClick={handleResetPalette}
            >
              RESET COLORS
            </button>
          </div>
        </div>
      </div>
      <div
         className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <ColorV2 color1={color1} color2={color2} />
      </div>
    </section>
  );
}

export default GlobalColors;

const getOptionFromColor = (mainColor, secondaryColor) => {
  const option = options.find(
    (o) => o.color1 === mainColor && o.color2 === secondaryColor
  );
  console.log(options.indexOf(option));
  return options.indexOf(option) || 0;
};