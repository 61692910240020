import React from "react";

export const TYPE_CLASS = {
  SAVE: "save-button",
  SAVEAS: "save-as-button",
  THEMES: "themes-button",
  PREVIEW: "preview-button",
  PUBLISH: "publish-button",
  REMOVE: "delete-button",
  CANCEL: "cancel-button",
  CONTENT: "content-button",
  HOWTO: "howto-button",
  CONTENT_IMAGE: "content-button upload-image"
};

export const CustomButton = (props) => {
  const buttonStyles = {
    width: props.customWidth,
    marginLeft: props.customMarginLeft,
    paddingLeft: props.customPaddingLeft,
    textAlign: props.alignLeft ? "left" : "center",
    opacity: props.disabled ? 0.5 : 1,
    cursor: props.disabled ? "not-allowed" : "pointer",
    display: props.display,
  };

  const buttonLinkStyles = {
    fontSize: '14px', 
    marginBottom: '0em',
  };

  const buttonClassName = `custom-button ${TYPE_CLASS[props.type]} ${
    props.icon ? "btn with-icon" : ""
  }`;

  const handleClick = () => {
    if (!props.disabled && typeof props.onClick === "function") {
      props.onClick();
    }
  };

  // If href prop exists, render an anchor tag
  if (props.href) {
    return (
      <a
        style={buttonStyles}
        className={buttonClassName}
        href={props.href}
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Security best practice
      >
        {props.label && <div style={buttonLinkStyles}>{props.label}</div>}
        {props.icon && (
          <span style={{ paddingLeft: "10px" }}>
            <i className="fa fa-trash-o" />
          </span>
        )}
        {props.counter && (
          <span className="content-counter">{props.itemsCounter}</span>
        )}
      </a>
    );
  }

  // Otherwise render a div
  return (
    <div style={buttonStyles} className={buttonClassName} onClick={handleClick}>
      {props.label && <span>{props.label}</span>}
      {props.icon && (
        <span style={{ paddingLeft: "10px" }}>
          <i className="fa fa-trash-o" />
        </span>
      )}
      {props.counter && (
        <span className="content-counter">{props.itemsCounter}</span>
      )}
    </div>
  );
};
