import React from "react";
import { Modal, Col } from "reactstrap";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import WarningIcon from "./items/WarningIcon";
import { useTranslation } from "react-i18next"; // Importa el hook de traducción

export const ModalAlertConfiguration = ({
  onCancel,
  isOpen,
  hasProductAlert,
  hasCollectionAlert,
}) => {
  const { t } = useTranslation(); // Usa el hook dentro del cuerpo de la función

  return (
    <Modal isOpen={isOpen} className="modal-dialog-centered confirm-remove-modal">
      <div className="publish-modal-header-alert">
        <div>
          <WarningIcon />
        </div>
        <div
          className="publish-modal-message"
          style={{
            display: "block",
            height:
              (hasProductAlert || hasCollectionAlert) &&
              !(hasProductAlert && hasCollectionAlert)
                ? "80px"
                : undefined,
          }}
        >
          {hasProductAlert && (
            <div>
              <span style={{ color: "#660000", fontWeight: "bold" }}>
                {t("homeModalAlertConfiguration.reminder")}{" "}
              </span>
              {t("homeModalAlertConfiguration.productAlert")}
            </div>
          )}
          {hasCollectionAlert && (
            <div>
              <span style={{ color: "#660000", fontWeight: "bold" }}>
                {t("homeModalAlertConfiguration.reminder")}{" "}
              </span>
              {t("homeModalAlertConfiguration.collectionAlert")}
            </div>
          )}
        </div>
      </div>
      <div className="publish-modal-buttons-alert">
        <Col xs="14">
          <CustomButton type="CANCEL" onClick={onCancel} label={t("buttons.close").toUpperCase()} />
        </Col>
      </div>
    </Modal>
  );
};