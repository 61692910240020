/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "reactstrap";
import { useTranslation } from "react-i18next";

import "../../Styles/modules/_modalVersionSelector.scss";

export const ModalSave = ({ 
  isOpen,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(''); // Local state to hold the input value

  // Handle input changes
  const handleInputChange = (event) => {
    setName(event.target.value);
  };

   // Handle save
   const handleSave = () => {
    onConfirm(name); // Pass the input value back to the parent
    setName(''); // Reset the input value
  };

  return (
    <Modal isOpen={isOpen} className="transitionSelector preview modalSave isOpen">
        <section>
      <div className="modal__container">
        <div className="modal__title">{t("homeModalSave.title")}:</div>
        <div className="d-flex justify-content-center">
          <input
              className="input"
              type="text"
              value={name}
              onChange={handleInputChange} // Handle input changes
            />
        </div>
        <div className="modal__buttons">
        <button
              type="button"
              className="close__button modal-button cancel-button"
              onClick={onClose}
            >
              {t("buttons.close")}
            </button>
            <button
              type="button"
              className="close__button modal-button save-button"
              onClick={handleSave}
              disabled={name.length < 3} // Disable the button if the name is less than 3 characters
            >
              {t("buttons.done")}
            </button>
        </div>
      </div>
    </section>
      
    </Modal>
  );
};
