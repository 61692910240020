/* eslint-disable jsx-a11y/anchor-is-valid */
import RadioButtonSlide from "../../common/radioButtonSlide";

import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButtonIcon from "../../common/RadioButtonIcon";
import BoxText from "../../common/BoxText";
import QuantityStepper from "./QuantityStepper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchCurrentConfigTheme,
  fetchCheckoutStepperData,
  updateCheckoutStepperData,
} from "../../../api";

function CartCheckoutQtyStepper({ storeKey }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState();
  const [block, setBlock] = useState("theme1");
  const [isColor, setIsColor] = useState("#CCCCCC");
  const [isActive, setActive] = useState(true);
  const [color1, setColor1] = useState();
  const [templateID, setTemplateID] = useState();

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  useEffect(() => {
    // This function is run whenever `isActive` changes
    console.log("isActive has changed:", isActive);
    updateColorComponent();
  }, [isActive]); // Dependency array. Only re-run the effect if `isActive` changes.

  const getCurrentConfigTheme = () => {
    setIsLoading(true);
    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

    // API
    fetchCheckoutStepperData(storeKey, templateID)
      .then((response) => {
        // Here's the change
        const data = response.data;
        const colorData = data.color;
        const cartData = data.checkout;

        console.log("cartData", cartData);

        if (colorData) {
          setColor1(colorData.mainColor);
        }
        if (cartData) {
          setIsSelected(Number(cartData.theme));
          setBlock("theme" + cartData.theme);
          setIsColor(cartData.color || "#CCCCCC");
          setActive(cartData.color ? true : false);
        } else {
          setIsColor("#CCCCCC");
          setIsSelected(1);
        }

        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching cart checkout data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  const updateColorComponent = () => {
    console.log("updateColorComponent isActive:", isActive);
    if (isActive == false) {
      setIsColor(color1);
    }
  };

  const updateConfig = async () => {
    setIsSaving(true);

    // API
    updateCheckoutStepperData(
      storeKey,
      templateID,
      isSelected,
      isActive ? isColor : null
    )
      .then((response) => {
        console.log("response", response);
        updateColorComponent();
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateCheckoutStepperData:", error);
      });
  };

  const checkedChange = (e) => {
    setBlock(e.target.name);
    setIsSelected(Number(e.target.value));
  };

  const colorChange = (e) => {
    setIsColor(e.target.value);
    setActive(true);
  };

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <section className="--checkoutStepper productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.loading")}</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.saving")}</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">{t("checkout.title")}:</h2>
          <nav
            className="componentNav"
            style={{  marginTop: "10px" }}
          >
            <ul className="componentList">
              <li className="itemList">
                <Link to="/cart" style={{ color: "#6D7A8B" }}>
                  LIST STYLE
                </Link>
              </li>
              <li className="itemList active">
                QUANTITY STEPPER
                <BoxText handleSaveButton={updateConfig}>
                {t("checkout.descriptionQuantityStepper")}                
                </BoxText>
              </li>
              <li className="itemList">
                <Link to="/bottom" style={{ color: "#6D7A8B" }}>
                  BOTTOM
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row">
          <h2 className="title">Style:</h2>
          <div className="col settings radios">
            <RadioButtonIcon
              label="CIRCLES"
              id="theme1"
              value={1}
              onChange={checkedChange}
              check={isSelected}
              title="Quantity Stepper Circles"
              description=""
              video="./sidevideos/checkoutStepperCircle.mp4"
            />
            <RadioButtonIcon
              label="SQUARES"
              id="theme2"
              value={2}
              onChange={checkedChange}
              check={isSelected}
              title="Quantity Stepper Square"
              description=""
              video="./sidevideos/checkoutStepperSquare.mp4"
            />
            <RadioButtonIcon
              label="FULL BACKGROUND CIRCLES"
              id="theme3"
              value={3}
              onChange={checkedChange}
              check={isSelected}
              title="Quantity Stepper Full Background Circles"
              description=""
              video="./sidevideos/checkoutStepperFullBackgroundCircles.mp4"
            />
            <RadioButtonIcon
              label="B&W CIRCLES"
              id="theme4"
              value={4}
              onChange={checkedChange}
              check={isSelected}
              title="Quantity Stepper Black and White"
              description=""
              video="./sidevideos/checkoutStepperBWCircles.mp4"
            />
            <RadioButtonIcon
              label="B&W FULL ROUNDED"
              id="theme5"
              value={5}
              onChange={checkedChange}
              check={isSelected}
              title="Quantity Stepper Black and White Rounded"
              description=""
              video="./sidevideos/checkoutStepperBWRounded.mp4"
            />
          </div>
        </div>
        {block !== "theme4" && block !== "theme5" && (
          <div className="settingsGroup row">
            <h2 className="title">Colors:</h2>
            <div className="row radioCustom --colors">
              <RadioButtonSlide
                label="CUSTOM COLORS"
                id="customColors"
                isActive={isActive}
                onClick={handleToggle}
              />
              <div className="contentColors">
                <input
                  type="text"
                  name="textColors"
                  className="textColor"
                  value={isColor}
                  onChange={colorChange}
                />
                <input
                  type="color"
                  name="colors"
                  className="pickColor"
                  value={isColor}
                  onChange={colorChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <QuantityStepper theme={block} color={isColor} />
      </div>
    </section>
  );
}

export default CartCheckoutQtyStepper;
