import React, { useEffect, useState, useRef } from "react";
import { not, isNil } from "ramda";
import { Container, Row, Col } from "reactstrap";
import { DragDropContext } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { WysiwygColumn } from "../../../wysiwyg-column";
import { Configurator } from "../configurator";
import { MarketColumn } from "../../../market-column";
import {
  placeComponentRequest,
  reArrangePlacedComponents,
  removeComponentFromStack,
  clearComponentSelection,
  init,
  selectComponent,
  getAvailableComponents,
  getPlacedComponents,
  getSelectedComponent,
  saveComponentConfig,
  updateConfigurationOfSelectedComponent,
  getConfig,
  getCurrentTheme,
  loadTemplate,
  reLoadTemplate,
} from "../../../../ducks";

import {
  duplicateTheme,
  setCurrentTheme,
  publishCurrentTheme,
} from "../../../../api";

import useSetAnimation, {
  IModalAction,
} from "../../../utils/hooks/useSetAnimation";
import playBlueIcon from "../../../../assets/home/play-blue-icon.png";
import LoadingImage from "../../../img/FengLoaderWhitePNG.png";
import AppBar from "../../../appbar/appbar";

import { CustomButton } from "./custom-button";
import { sortComponentsByOrderNumber } from "../../../utils/utilities";

// Modals
import { ModalSave } from "../../../modals/modalSave";
import { ModalTemplate } from "../../../modals/modalTemplate";
import { ModalPreview } from "../../../modals/modalPreview";
import { ModalPublish } from "../../../modals/modalPublish";
import { ModalPublishAlert } from "../../../modals/ModalPublishAlert";
import { ModalAlertConfiguration } from "../../../modals/modalAlertConfiguration";
import { ModalIntroVideo } from "../../../modals/modalIntroVideo";

import { useTranslation } from "react-i18next";

export const DROPPABLE_WYSIWYG = "DROPPABLE_WYSIWYG";
export const MARKET_COLUMN = "MARKET_COLUMN";

const DragableLayoutBase = ({
  addComponent,
  availableComponents,
  placedComponents,
  reArrangeIdx,
  onRemoveItem,
  onSaveItem,
  onLoadTemplate,
  selectedComponent,
  onClearSelection,
  onSelectComponent,
  onInit,
  onUpdateComponent,
  onReloadTemplate,
  storeKey,
  userType,
  currentTheme,
}) => {
  const { t } = useTranslation();
  
  const [isFirstTimeLoaded, setIsFirstTimeLoaded] = useState(false); // Loading state
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isAddingComponent, setIsAddingComponent] = useState(false); // Adding Component
  const [animation, setAnimation] = useSetAnimation();
  const [globalOptions, setGlobalOptions] = useState([]);

  // Modals
  const [isShowingSaveAs, setIsShowingSaveAs] = useState(false); // Save as
  const [isShowingTheme, setIsShowingTheme] = useState(false); // Themes
  const [isShowingQR, setIsShowingQR] = useState(false); // QR
  const [isShowingIntroVideo, setIsShowingIntroVideo] = useState(false); // Show Intro Video
  const [isShowingPublishAlert, setIsShowingPublishAlert] = useState(false); // Are you sure you want to publish?
  const [
    isShowingPublishAlertAppStoreInfo,
    setIsShowingPublishAlertAppStoreInfo,
  ] = useState(false);

  const [
    isShowingAlertConfiguration,
    setIsShowingAlertConfiguration,
  ] = useState(false); // Are you sure you want to publish?

  // Alert Modal
  const [hasProductAlert, setHasProductAlert] = useState(false);
  const [hasCollectionAlert, setHasCollectionAlert] = useState(false);

  // Template data
  const [templateData, setTemplateData] = useState({
    templateID: null,
    name: null,
  });

  const hasInitializedRef = useRef(false);

  const userTypeToGroup = {
    monthly1: "paid1",
    yearly1: "paid1",
    monthly2: "paid2",
    yearly2: "paid2",
    monthly3: "full",
    yearly3: "full",
    trial: "full",
  };

  const disabledComponentsMapping = {
    paid1: [
      2,
      3,
      25,
      5,
      7,
      28,
      29,
      30,
      31,
      10,
      32,
      33,
      34,
      12,
      13,
      14,
      19,
      20,
      21,
      22,
      23,
      24,
      15,
      16,
      41,
      42,
    ],
    paid2: [2, 3, 27, 28, 29, 30, 10, 32, 33, 12, 20, 22, 24, 15, 42],
  };

  const shouldHideButton = () => {
    const hideButtonGroups = ["paid1", "paid2"];
    const userGroup = userTypeToGroup[userType] || "full";
    return hideButtonGroups.includes(userGroup);
  };

  const shouldHideMenuButton = () => {
    return userType == "trial";
  };

  useEffect(() => {
    if (hasProductAlert || hasCollectionAlert) {
      setIsShowingAlertConfiguration(true);
    }
  }, [hasProductAlert, hasCollectionAlert]);


useEffect(() => {
  if (!hasInitializedRef.current && templateData.templateID && templateData.name) {
    hasInitializedRef.current = true;
    setIsFirstTimeLoaded(true);
    onInit({
      templateID: templateData.templateID,
      storeKey,
    });
  }
}, [storeKey, templateData]);

  useEffect(() => {
    if (currentTheme) {
      console.log("currentTheme", currentTheme);
      setTemplateData({
        templateID: currentTheme.templateID,
        name: currentTheme.name,
      });
    }
  }, [currentTheme]);

  useEffect(() => {
    console.log("templateData useEffect:");
    if (templateData.templateID && templateData.name) {
      const templateId = templateData.templateID;
      setIsLoading(true);
      setGlobalOptions([]);

      console.log("templateData changed:", templateData);
      console.log("templateID", templateId);
      console.log("storeKey", storeKey);

      onReloadTemplate({ storeKey: storeKey, templateID: templateId });

      // Update local templateID
      localStorage.setItem("rootTemplateID", templateId);
    }
  }, [templateData]);
  //}, [templateData, onInit]);

  useEffect(() => {
    if (!isNil(selectedComponent)) {
      setAnimation(IModalAction.SHOW);
    }
  }, [selectedComponent]);

  useEffect(() => {
    console.log("placedComponents changed:", placedComponents);
    if (isFirstTimeLoaded === false && globalOptions.length == 0) {
      loadSelectedTemplateForFirstTime();
    } else {
      setIsAddingComponent(false);
      setIsLoading(false);

      placedComponents.forEach((op) => {
        // Check if uuid is already present in globalOptions
        const isDuplicate = globalOptions.some((item) => item.uuid === op.uuid);

        // Check if op.title and op.componentId exist
        const hasRequiredProperties = op.title && op.componentId;

        if (!isDuplicate && hasRequiredProperties) {
          console.log("forEach placedComponents", op);
          globalOptions.push({
            uuid: op.uuid,
            configuration: op.configurationOptions,
          });
        }
      });
      console.log("handle LOAD globalOptions.length", globalOptions.length);
    }
  }, [placedComponents]);

  const onDragEnd = ({ destination, source, draggableId }) => {
    //debugger;

    if (!destination) {
      console.log("NO DESTINATION");
      return;
    }

    const isSameDestination = destination.droppableId === source.droppableId;
    const isSameIdx = destination.index === source.index;

    if (destination.droppableId === DROPPABLE_WYSIWYG) {
      if (!isSameDestination) {
        const movedComponent = availableComponents.find(
          ({ componentId }) => componentId === draggableId
        );
        // ADD COMPO
        addComponent({
          component: movedComponent,
          order: destination.index,
          templateID: templateData.templateID,
          storeKey: storeKey,
        });
        setIsAddingComponent(true);
        return;
      }
      if (!isSameIdx) {
        // REORDER COMPOS
        reArrangeIdx({
          oldIdx: source.index,
          newIdx: destination.index,
          templateID: templateData.templateID,
          storeKey: storeKey,
        });
        return;
      }
    }
  };

  // Load First Theme
  const loadSelectedTemplateForFirstTime = async () => {
    console.log("loadSelectedTemplateForFirstTime", storeKey);
    setIsLoading(true);
    //
    onLoadTemplate({ storeKey });
  };

  const handleSave = async () => {
    if (!templateData.templateID || !templateData.name) {
      return; // Exit if conditions aren't met
    }

    setHasProductAlert(false);
    setHasCollectionAlert(false);
    setIsSaving(true);

    if (globalOptions.length) {
      saveGlobalOptions();
    }

    // Delay saving state update and check for empty products or categories
    setTimeout(() => {
      setIsSaving(false);
      checkForEmptyBlocks();
    }, 1000); // Delay of 1 second (1000 milliseconds)
  };

  const saveGlobalOptions = () => {
    // Close the selected Item for bug after saving and showing selected component wrong data
    onClearSelection();
    setAnimation(IModalAction.HIDE);

    globalOptions.forEach((op) => {
      onSaveItem({
        uuid: op.uuid,
        configuration: op.configuration,
        storeKey: storeKey,
        templateID: templateData.templateID,
      });
    });
  };

  const checkForEmptyBlocks = () => {
    placedComponents.forEach((op) => {
      const titleLowercase = op.title.toLowerCase();

      // Search for block products
      if (titleLowercase.includes("product")) {
        const updateContentOption = op.configurationOptions.find(
          (option) => option.label === "Update content"
        );

        if (
          !updateContentOption ||
          !updateContentOption.products ||
          updateContentOption.products.length === 0
        ) {
          setHasProductAlert(true);
        }
      }

      // Search for categories
      if (titleLowercase.includes("catego")) {
        const updateContentOption = op.configurationOptions.find(
          (option) => option.label === "Update category"
        );

        if (
          !updateContentOption ||
          !updateContentOption.products ||
          updateContentOption.products.length === 0
        ) {
          setHasCollectionAlert(true);
        }
      }
    });
  };

  // Duplicate theme
  const duplicateTemplate = (newName) => {
    if (newName.length > 3) {
      if (templateData.templateID && templateData.name) {
        setIsShowingSaveAs(false); // Close the modal
        setIsSaving(true);
        //setIsLoading(true);
        // API
        duplicateTheme(storeKey, templateData.templateID, newName)
          .then((response) => {
            setIsSaving(false);
            const data = response.data;
            if (data) {
              console.log("repsonse", data);
              // Update current theme in Firebase
              setCurrentThemeAsDev(data, newName);
            }
          })
          .catch((error) => {
            console.error("Error renameTheme:", error);
          });
      } else {
        console.error("Template ID not found");
        setIsShowingSaveAs(false); // Close the modal
      }
    } else {
      console.error("Name lenght < 3");
      setIsShowingSaveAs(false); // Close the modal
    }
  };

  // Update Current Theme
  const setCurrentThemeAsDev = (themeID, name) => {
    setIsLoading(true);
    // API
    setCurrentTheme(storeKey, themeID)
      .then(() => {
        setTemplateData({ templateID: themeID, name: name });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const doPublish = () => {
    setIsShowingPublishAlert(false); // Close the modal
    if (templateData.templateID && templateData.name) {
      setIsSaving(true);
      // API
      publishCurrentTheme(storeKey, templateData.templateID)
        .then((response) => {
          setTimeout(() => {
            setIsSaving(false); // Close the modal
            setIsShowingPublishAlertAppStoreInfo(true); // show publish modal
          }, 1000); // Delay of 1 second (1000 milliseconds)
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("Template ID not found");
      setIsSaving(false); // Close the modal
    }
  };

  // Publish theme
  const handlePublish = () => {
    setIsShowingPublishAlert(true);
  };

  const handleOnUpdateGlobalConfig = (options) => {
    console.log("handleOnUpdateGlobalConfig", options);
    setGlobalOptions(options);
  };

  const handleOnUpdateComponentConfig = (uuid, configuration) => {
    onUpdateComponent(configuration);
  };

  const handleSaveAs = () => {
    setIsShowingSaveAs(true);
  };

  const handleTheme = () => {
    setIsShowingTheme(true);
  };

  const handlePreview = () => {
    setIsShowingQR(true);
  };

  const handleOnClose = () => {
    setTimeout(() => {
      onClearSelection();
    }, 500);
    setAnimation(IModalAction.HIDE);
  };

  const handleSelectTemplate = (templateID, name) => {
    console.log("handleSelectTemplate");
    // Update current theme in Firebase
    setCurrentThemeAsDev(templateID, name);
  };

  const handleShowIntro = () => {
    setIsShowingIntroVideo(true);
  };

  // Layout
  const groupComponentsByType = (components, userType) => {
    // Function to check if the component should be disabled
    const isDisabled = (componentId) => {
      const userGroup = userTypeToGroup[userType] || "full";
      return (
        disabledComponentsMapping[userGroup] &&
        disabledComponentsMapping[userGroup].includes(componentId)
      );
    };

    // Separate and sort components by type and then by enabled status
    const sortAndGroup = (componentTypeFilter) => {
      const filteredComponents = components.filter(componentTypeFilter);

      const enabledComponents = filteredComponents.filter(
        (c) => !isDisabled(c.componentId)
      );
      const disabledComponents = filteredComponents.filter((c) =>
        isDisabled(c.componentId)
      );

      return [
        ...sortComponentsByOrderNumber(enabledComponents),
        ...sortComponentsByOrderNumber(disabledComponents),
      ];
    };

    // Define component type filters
    const productFilter = (c) => c.title.toLowerCase().includes("product");
    const categoryFilter = (c) => c.title.toLowerCase().includes("catego");
    const separatorFilter = (c) =>
      c.title.toLowerCase().includes("separator") ||
      c.title.toLowerCase().includes("botones");
    const imageFilter = (c) =>
      c.title.toLowerCase().includes("image") &&
      !c.title.toLowerCase().includes("news");
    const articleFilter = (c) => c.title.toLowerCase().includes("news");
    const imageVideos = (c) => c.title.toLowerCase().includes("video");

    // Group and sort components by type
    const groupedBy = [
      "PRODUCTS",
      ...sortAndGroup(productFilter),
      "CATEGORIES",
      ...sortAndGroup(categoryFilter),
      "VIDEOS",
      ...sortAndGroup(imageVideos),
      "IMAGES",
      ...sortAndGroup(imageFilter),
      "SEPARATORS",
      ...sortAndGroup(separatorFilter),
      "ARTICLES",
      ...sortAndGroup(articleFilter),
    ];

    // Add isDraggable property to component objects
    const updatedGroupedBy = groupedBy.map((component) => {
      if (typeof component === "object") {
        const disableComponent = isDisabled(component.componentId);
        return { ...component, isDraggable: !disableComponent };
      }
      return component;
    });

    // Logging the updated components
    updatedGroupedBy.forEach((item) => {
      if (typeof item === "object") {
        console
          .log
          //`Component: ${item.title}, ID: ${item.componentId}, isDraggable: ${item.isDraggable}`
          ();
      } else {
        //console.log(`Label: ${item}`);
      }
    });

    return updatedGroupedBy;
  };

  return (
    <div className="market-layout">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.loading")}</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.saving")}</div>
        </div>
      )}
      {isAddingComponent && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.addingNewCompo")}</div>
        </div>
      )}
      {isShowingIntroVideo && (
        <ModalIntroVideo
          isOpen={isShowingIntroVideo}
          onClose={() => setIsShowingIntroVideo(false)}
        />
      )}
      {isShowingTheme && (
        <ModalTemplate
          isOpen={isShowingTheme}
          onClose={() => setIsShowingTheme(false)}
          onSelectTemplate={handleSelectTemplate}
          currentTemplateID={templateData.templateID}
          userType={userType}
          storeKey={storeKey}
        />
      )}
      {isShowingSaveAs && (
        <ModalSave
          isOpen={isShowingSaveAs}
          onClose={() => setIsShowingSaveAs(false)}
          onConfirm={duplicateTemplate}
        />
      )}
      {isShowingQR && (
        <ModalPreview
          isOpen={isShowingQR}
          onClose={() => setIsShowingQR(false)}
          templateID={templateData.templateID}
          templateName={templateData.name}
          storeKey={storeKey}
        />
      )}
      {isShowingPublishAlert && (
        <ModalPublish
          isOpen={isShowingPublishAlert}
          onCancel={() => setIsShowingPublishAlert(false)}
          onConfirm={doPublish}
        />
      )}{" "}
      {isShowingPublishAlertAppStoreInfo && (
        <ModalPublishAlert
          isOpen={isShowingPublishAlertAppStoreInfo}
          onCancel={() => setIsShowingPublishAlertAppStoreInfo(false)}
        />
      )}
      {isShowingAlertConfiguration && (
        <ModalAlertConfiguration
          isOpen={isShowingAlertConfiguration}
          onCancel={() => setIsShowingAlertConfiguration(false)}
          onConfirm={doPublish}
          hasProductAlert={hasProductAlert}
          hasCollectionAlert={hasCollectionAlert}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Container fluid className="layout-container">
          <Row className="main-row no-gutters">
            <AppBar />
            <div className="toolbar-b">
              <div className="child-container">
                <MarketColumn
                  availableComponents={groupComponentsByType(
                    availableComponents,
                    userType
                  )}
                  droppableId={MARKET_COLUMN}
                />
              </div>
            </div>
            <Col className="nopadding col">
              <WysiwygColumn
                placedComponents={placedComponents}
                droppableId={DROPPABLE_WYSIWYG}
                selectedComponentUUID={selectedComponent?.uuid}
                onSelectComponent={onSelectComponent}
              />
            </Col>
            <div
              className={
                shouldHideButton() || shouldHideMenuButton()
                  ? "buttons-container-no-saveas"
                  : "buttons-container"
              }
            >
              <CustomButton
                type="SAVE"
                label={t("buttons.save").toUpperCase()}
                onClick={handleSave}
              />
              {!shouldHideButton() && (
                <CustomButton
                  type="SAVEAS"
                  label={t("buttons.saveAs").toUpperCase()}
                  onClick={handleSaveAs}
                />
              )}
              <CustomButton
                type="THEMES"
                label={t("buttons.themes").toUpperCase()}
                onClick={handleTheme}
              />
              <CustomButton
                type="PREVIEW"
                label={t("buttons.preview").toUpperCase()}
                onClick={handlePreview}
              />
              {!shouldHideMenuButton() && (
                <CustomButton
                  type="PUBLISH"
                  label={t("buttons.publish").toUpperCase()}
                  onClick={handlePublish}
                />
              )}
              <div className="text-container-wrapper">
                <div className="text-container">{t("common.themeTitle")}</div>
                <div className="text-container-theme">
                  {templateData.name || ("home.noTemplateSelected")}
                </div>
              </div>
            </div>
            <div className="desc-container">
              <div className="descriptions">
                <div className="desc-one">
                  {t("home.introTitle")
                    .split("<br />")
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        <a>{line}</a>
                        <br />
                      </React.Fragment>
                    ))}
                </div>
                <div
                  className="desc-two"
                  dangerouslySetInnerHTML={{ __html: t("home.introText") }}
                />
                <div className="desc-three" onClick={handleShowIntro}>
                  <img src={playBlueIcon} />
                  {t("home.introButton")}
                </div>
              </div>
            </div>
            <Col xs="4" className="col" style={animation}>
              <Configurator
                selectedComponent={selectedComponent}
                onClose={handleOnClose}
                onRemoveComponent={onRemoveItem}
                onConfigChange={handleOnUpdateGlobalConfig}
                onCompoUpdate={handleOnUpdateComponentConfig}
                storeKey={storeKey}
                userType={userType}
                templateID={templateData.templateID}
              />
            </Col>
          </Row>
        </Container>
      </DragDropContext>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  availableComponents: getAvailableComponents(state),
  placedComponents: getPlacedComponents(state),
  selectedComponent: getSelectedComponent(state),
  config: getConfig(state),
  currentTheme: getCurrentTheme(state),
  storeKey: ownProps.storeKey, // Accessing storeKey from ownProps
});

export const DragableLayout = connect(mapStateToProps, {
  addComponent: placeComponentRequest,
  reArrangeIdx: reArrangePlacedComponents,
  onRemoveItem: removeComponentFromStack,
  onSaveItem: saveComponentConfig,
  onClearSelection: clearComponentSelection,
  onSelectComponent: selectComponent,
  onUpdateComponent: updateConfigurationOfSelectedComponent,
  onInit: init, // Maybe init elsewhere,
  onLoadTemplate: loadTemplate,
  onReloadTemplate: reLoadTemplate,
})(DragableLayoutBase);
