import { is } from "ramda";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { sortComponentConfigs } from "../../../utils/utilities";
import { ConfSection } from "./confsection";
import { RemoveWithConfirmRow } from "./remove-button";
import { useTranslation } from "react-i18next";

export const Configurator = ({
  selectedComponent,
  onRemoveComponent,
  onClose,
  onConfigChange,
  onCompoUpdate,
  templateID,
  storeKey,
  userType
}) => {
  const { t } = useTranslation();
  const [newConfigurationOptions, setNewConfigurationOptions] = useState([]);
  const [globalOptions, setGlobalOptions] = useState([]);

  useEffect(() => {
    if (selectedComponent) {
      console.log("selectedComponent", selectedComponent);
      console.log("globalOptions", globalOptions);
      const found = globalOptions.find(
        (gc) => gc.uuid === selectedComponent.uuid
      );
      if (found) {
        console.log("selectedComponent found.configuration", found.configuration);
        // settings global options if exist
        setNewConfigurationOptions(found.configuration);
      } else {
        console.log("selectedComponent selectedComponent.title", selectedComponent.title);
        console.log("selectedComponent selectedComponent.configurationOptions", selectedComponent.configurationOptions);
        
        const sortedConfigs = sortComponentConfigs(
          selectedComponent.configurationOptions,
          selectedComponent.title
        );

        console.log("selectedComponent sortedConfigs", sortedConfigs);
        setNewConfigurationOptions(sortedConfigs);
      }
    }
  }, [selectedComponent]);

  const handleConfigurationChange = (label, value, parentOption) => {

    console.log("handleConfigurationChange label", label);
    console.log("handleConfigurationChange value", value);
    console.log("handleConfigurationChang parentOptione", parentOption);

    //debugger;
    let temp = JSON.parse(JSON.stringify(newConfigurationOptions));

    console.log("temp", temp);
    console.log("newConfigurationOptions", newConfigurationOptions);

    if (parentOption) {
      console.log("IS PARENTOPTION");
      
      for (const config of temp) {
        if (config.label === parentOption) {
          if (parentOption === "Action") {
            config.externalLink = value;
            break;
          }
          if (
            parentOption === "Image 1" ||
            parentOption === "Image 2" ||
            parentOption === "Image 3"
          ) {
            for (const op of config.options) {
              if (op.label === label) {
                if (value == "0" || value == "1" || value == "2") {
                  op.value = value;
                } else if (Array.isArray(value)) {
                  op.products = value;
                } else {
                  op.externalLink = value;
                }
                break;
              }
            }
            break;
          }
          for (const op of config.options) {
            if (op.label === label) {
              op.value = value;
              break;
            }
          }
        }
      }
    } else {
      for (const config of temp) {
        if (config.label === label) {
          if (
            label === "Update content" ||
            label === "Update product" ||
            label === "Update category"
          ) {
            config.products = value;
          } else {
            config.value = value;
          }
          break;
        }
      }
    }

    // saving global options temp
    const found = globalOptions.findIndex(
      (gc) => gc.uuid === selectedComponent.uuid
    );
    if (found !== -1) {
      console.log("found", found);
      globalOptions[found] = {
        uuid: selectedComponent.uuid,
        configuration: temp,
      };
    } else {
      console.log("push", found);
      globalOptions.push({ uuid: selectedComponent.uuid, configuration: temp });
    }
    
    // Fix to update products in the global options
    //selectedComponent.configurationOptions = temp

    console.log("globalOptions", globalOptions);
    console.log("temp", temp);

    onCompoUpdate(selectedComponent.uuid, temp)
    onConfigChange(globalOptions);
    setGlobalOptions(globalOptions);
    setNewConfigurationOptions(temp);
  };

  /**
   * Workaround function to hide configs depending on another "sister" config.
   * (Hidding confings meant to be parent -> children instead sisters that's way im doing this)
   * @param configLabel
   * @returns hide true or false
   */
  const needToHide = (configLabel) => {
    if (selectedComponent.title.includes("Slider categorias")) {
      const categoryTitleConfig = newConfigurationOptions.find(
        (config) => config.label === "Category title"
      );
      const noneCategoryTitle =
        categoryTitleConfig &&
        categoryTitleConfig.options &&
        categoryTitleConfig.options[0] &&
        categoryTitleConfig.options[0].value === 2;
      if (
        noneCategoryTitle &&
        (configLabel === "Background Color" ||
          configLabel === "Category title position" ||
          configLabel === "Title Color")
      ) {
        return true;
      }
      return false;
    }

    return false;
  };

  /**
   * Workaround function to hide NESTED configs depending on another "sister" config.
   * (Hidding confings meant to be parent -> children instead sisters that's way im doing this)
   * @param options
   * @returns the same options with an additional property hide true or false
   */
  const needToHideNested = (options) => {
    if (options) {
      const autoScroll = options.find((o) => o.label === "Auto Scroll");
      if (autoScroll) {
        const timeDelay = options.find((o) => o.label === "Time Delay");
        if (timeDelay) {
          timeDelay.hide = !autoScroll.value;
        }
      }
    }
    return options;
  };

  const images = imageMapper(t);

const getRealTitle = (title) => {
  return images[title]?.realTitle || null;
};

const getRealDescription = (title) => {
  return images[title]?.realDescription || null;
};

  return (
    <div className="configurator">
      <Container className="nomargin nopadding">
        <Row className="title-bar">
          {selectedComponent && (
            <Col >
              <div className="configuration-side-title">{getRealTitle(selectedComponent.title)}</div>
              <div className="configuration-side-desc" style={{ whiteSpace: 'pre-wrap' }}>{getRealDescription(selectedComponent.title)}</div>
            </Col>
          )}
          <Col lg={1} className="d-flex justify-content-end align-items-end pb-4"  style={{ marginBottom: "129px",  marginLeft: "20px" }}>
            <Button close size="lg" onClick={() => onClose()} />
          </Col>
        </Row>
        <Container>
    {console.log("CONTAINER:", newConfigurationOptions)}
    {selectedComponent &&
        newConfigurationOptions.map(
            ({ label, type, options, value, externalLink }) => {
                return (
                    <ConfSection
                        title={selectedComponent.title}
                        label={label}
                        type={type}
                        options={needToHideNested(options)}
                        value={value}
                        key={`${selectedComponent.title}-${label}`}
                        externalLink={externalLink}
                        onChange={handleConfigurationChange}
                        hide={needToHide(label)} // workaround para ocultar configuraciones que dependen de configuraciones "hermanas"
                        storeKey={storeKey}
                        userType={userType}
                    />
                );
            }
        )}
    <RemoveWithConfirmRow
        onRemove={() => {
            onRemoveComponent({
                uuid: selectedComponent.uuid,
                templateID: templateID,
                storeKey: storeKey
            });
            // Close toolbar after remove block to avoid showing empty config
            onClose();
        }}
    />
</Container>

      </Container>
    </div>
  );
};

export const imageMapper = (t) => ({
  "Producto Destacado": {
    realTitle: t("imageMapper.featuredProductTitle"),
    realDescription: t("imageMapper.featuredProductDescription"),
  },
  "Slider con listado de productos configurables #1 - left": {
    realTitle: t("imageMapper.productCarouselLeftTitle"),
    realDescription: t("imageMapper.productCarouselLeftDescription"),
  },
  "Slider con listado de productos configurables #1 - right": {
    realTitle: t("imageMapper.productCarouselRightTitle"),
    realDescription: t("imageMapper.productCarouselRightDescription"),
  },
  "Slider con listado de productos configurables #2": {
    realTitle: t("imageMapper.productCarousel2Title"),
    realDescription: t("imageMapper.productCarousel2Description"),
  },
  "Slider con listado de productos configurables #3": {
    realTitle: t("imageMapper.productCarousel3Title"),
    realDescription: t("imageMapper.productCarousel3Description"),
  },
  "Slider con listado de productos configurables #4": {
    realTitle: t("imageMapper.productCarousel4Title"),
    realDescription: t("imageMapper.productCarousel4Description"),
  },
  "Carousel de productos": {
    realTitle: t("imageMapper.smallProductCarouselTitle"),
    realDescription: t("imageMapper.smallProductCarouselDescription"),
  },
  "Slider productos GRID - theme 1": {
    realTitle: t("imageMapper.productGridTheme1Title"),
    realDescription: t("imageMapper.productGridTheme1Description"),
  },
  "Slider productos GRID - theme 2": {
    realTitle: t("imageMapper.productGridTheme2Title"),
    realDescription: t("imageMapper.productGridTheme2Description"),
  },
  "Slider productos HORIZONTAL - theme 1": {
    realTitle: t("imageMapper.productSliderHorizontal1Title"),
    realDescription: t("imageMapper.productSliderHorizontal1Description"),
  },
  "Slider productos HORIZONTAL - theme 2": {
    realTitle: t("imageMapper.productSliderHorizontal2Title"),
    realDescription: t("imageMapper.productSliderHorizontal2Description"),
  },
  "Slider productos destacados - sliderFull": {
    realTitle: t("imageMapper.productSliderZoomTitle"),
    realDescription: t("imageMapper.productSliderZoomDescription"),
  },
  "Slider productos destacados - sliderFlip": {
    realTitle: t("imageMapper.productSlider3DTitle"),
    realDescription: t("imageMapper.productSlider3DDescription"),
  },
  "Slider productos destacados - sliderCards": {
    realTitle: t("imageMapper.productSliderOverlapTitle"),
    realDescription: t("imageMapper.productSliderOverlapDescription"),
  },
  "Slider productos destacados - sliderContinuous": {
    realTitle: t("imageMapper.productSliderLinearTitle"),
    realDescription: t("imageMapper.productSliderLinearDescription"),
  },
  "Image 100": {
    realTitle: t("imageMapper.image100Title"),
    realDescription: t("imageMapper.image100Description"),
  },
  "Image 75": {
    realTitle: t("imageMapper.image75Title"),
    realDescription: t("imageMapper.image75Description"),
  },
  "Image 50": {
    realTitle: t("imageMapper.image50Title"),
    realDescription: t("imageMapper.image50Description"),
  },
  "Image 25": {
    realTitle: t("imageMapper.image25Title"),
    realDescription: t("imageMapper.image25Description"),
  },
  "Image Double 100": {
    realTitle: t("imageMapper.imageDouble100Title"),
    realDescription: t("imageMapper.imageDouble100Description"),
  },
  "Image Double 50": {
    realTitle: t("imageMapper.imageDouble50Title"),
    realDescription: t("imageMapper.imageDouble50Description"),
  },
  "Botones": {
    realTitle: t("imageMapper.buttonTitle"),
    realDescription: t("imageMapper.buttonDescription"),
  },
  "Separator Line Text": {
    realTitle: t("imageMapper.separatorTextLineTitle"),
    realDescription: t("imageMapper.separatorTextLineDescription"),
  },
  "Separator Line": {
    realTitle: t("imageMapper.separatorLineTitle"),
    realDescription: t("imageMapper.separatorLineDescription"),
  },
  "Separator Text": {
    realTitle: t("imageMapper.separatorTextTitle"),
    realDescription: t("imageMapper.separatorTextDescription"),
  },
  "News No Image": {
    realTitle: t("imageMapper.blogNoImageTitle"),
    realDescription: t("imageMapper.blogNoImageDescription"),
  },
  "News Top Image": {
    realTitle: t("imageMapper.blogWithImageTitle"),
    realDescription: t("imageMapper.blogWithImageDescription"),
  },
  "Slider categorias con animaciones - sliderFull": {
    realTitle: t("imageMapper.categorySliderZoomTitle"),
    realDescription: t("imageMapper.categorySliderZoomDescription"),
  },
  "Slider categorias con animaciones - sliderFlip": {
    realTitle: t("imageMapper.categorySlider3DTitle"),
    realDescription: t("imageMapper.categorySlider3DDescription"),
  },
  "Slider categorias con animaciones - sliderCards": {
    realTitle: t("imageMapper.categorySliderOverlapTitle"),
    realDescription: t("imageMapper.categorySliderOverlapDescription"),
  },
  "Slider categorias con animaciones - sliderContinuous": {
    realTitle: t("imageMapper.categorySliderLinearTitle"),
    realDescription: t("imageMapper.categorySliderLinearDescription"),
  },
  "Carousel de categorías": {
    realTitle: t("imageMapper.smallCategoryCarouselTitle"),
    realDescription: t("imageMapper.smallCategoryCarouselDescription"),
  },
  "Video": {
    realTitle: t("imageMapper.videoTitle"),
    realDescription: t("imageMapper.videoDescription"),
  },
  "Video TikTok": {
    realTitle: t("imageMapper.videoTikTokTitle"),
    realDescription: t("imageMapper.videoTikTokDescription"),
  },
});