/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButtonSlide from "../../common/radioButtonSlide";
import RadioButtonIcon from "../../common/RadioButtonIcon";
import BoxText from "../../common/BoxText";
import AddToCartBlocks from "./AddToCartBlocks";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchCurrentConfigTheme,
  fetchProductDetailAddCartData,
  updateProductDetailAddCartData,
} from "../../../api";

function AddToCart({ storeKey }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState();
  const [block, setBlock] = useState();
  const [isColor, setIsColor] = useState("#CCCCCC");
  const [isActive, setActive] = useState(true);
  const [checkOutButtonTheme, setCheckOutButtonTheme] = useState();
  const [checkOutColor, setCheckOutColor] = useState();
  const [color1, setColor1] = useState();
  const [templateID, setTemplateID] = useState();

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  useEffect(() => {
    updateColorComponent();
  }, [isActive]);

  const getCurrentConfigTheme = () => {
    setIsLoading(true);

    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

    // API
    fetchProductDetailAddCartData(storeKey, templateID)
      .then((response) => {
        // Here's the change
        const data = response.data;
        const colorData = data.color;
        const productData = data.product;

        console.log("productData", productData);
        if (colorData) {
          setColor1(colorData.mainColor);
        }
        if (productData) {
          setIsSelected(Number(productData.checkOutButtonTheme));
          setIsColor(productData.checkOutColor || colorData.mainColor);
          setBlock("theme" + productData.checkOutButtonTheme);
          setActive(productData.checkOutColor ? true : false);
          setCheckOutButtonTheme(productData.checkOutButtonTheme);
          setCheckOutColor(productData.checkOutColor);
        }

        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching collection data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  const updateConfig = async () => {
    setIsSaving(true);

    // API
    updateProductDetailAddCartData(
      storeKey,
      templateID,
      isSelected,
      isActive ? isColor : null
    )
      .then((response) => {
        console.log("response", response);
        updateColorComponent();
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateProductDetailAddCartData:", error);
      });
  };

  const checkedChange = (e) => {
    setBlock(e.target.name);
    setIsSelected(Number(e.target.value));
  };

  const colorChange = (e) => {
    setIsColor(e.target.value);
    setActive(true);
  };

  const handleToggle = () => {
    setActive(!isActive);
  };

  const updateColorComponent = () => {
    if (isActive == false) {
      setIsColor(color1);
    }
  };

  return (
    <section className="--addToCart productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.loading")}</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.saving")}</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">{t("productDetails.cardTitle")}:</h2>
          <nav
            className="componentNav"
            style={{  marginTop: "10px" }}
          >
            <ul className="componentList">
              <li className="itemList">
                <Link to="/details" style={{ color: "#6D7A8B" }}>
                  PRODUCT CARD
                </Link>
              </li>
              <li className="itemList active">
                ADD TO CART
                <BoxText handleSaveButton={updateConfig}>
                {t("productDetails.add2CartDescription")}                
                </BoxText>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row">
          <h2 className="title">Themes:</h2>
          <div className="col settings radios">
            <RadioButtonIcon
              label="SQUARED"
              id="theme2"
              value={2}
              onChange={checkedChange}
              check={isSelected}
              title="Add to Cart - Squared"
              description=""
              video="./sidevideos/productDetailCart1.mp4"
            />
            <RadioButtonIcon
              label="ROUNDED"
              id="theme5"
              value={5}
              onChange={checkedChange}
              check={isSelected}
              title="Add to Cart - Rounded"
              description=""
              video="./sidevideos/productDetailCart2.mp4"
            />
            <RadioButtonIcon
              label="ROUNDED 2 WITH PRICE"
              id="theme4"
              value={4}
              onChange={checkedChange}
              check={isSelected}
              title="Add to Cart - Rounded With Price"
              description=""
              video="./sidevideos/productDetailCart3.mp4"
            />
            <RadioButtonIcon
              label="DOUBLE ROUNDED"
              id="theme1"
              value={1}
              onChange={checkedChange}
              check={isSelected}
              title="Add to Cart - Double Rounded"
              description=""
              video="./sidevideos/productDetailCart4.mp4"
            />
            <RadioButtonIcon
              label="DOUBLE HORIZONTAL"
              id="theme3"
              value={3}
              onChange={checkedChange}
              check={isSelected}
              title="Add to Cart - Double Rounded Horizontal"
              description=""
              video="./sidevideos/productDetailCart5.mp4"
            />
          </div>
        </div>

        <div className="settingsGroup row">
          <h2 className="title" style={{ marginTop: "40px" }}>
            Colors
          </h2>
          <div
            className="row radioCustom --colors"
            style={{ marginTop: "-57px", marginLeft: "135px", width: "77%" }}
          >
            <RadioButtonSlide
              label="CUSTOM COLORS"
              id="customColors"
              isActive={isActive}
              onClick={handleToggle}
            />
            <div className="contentColors">
              <input
                type="text"
                name="textColors"
                className="textColor"
                value={isColor}
                onChange={colorChange}
              />
              <input
                type="color"
                name="colors"
                className="pickColor"
                value={isColor}
                onChange={colorChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <AddToCartBlocks theme={block} color={isColor} />
      </div>
    </section>
  );
}

export default AddToCart;
