import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Droppable } from "react-beautiful-dnd";
import classnames from "classnames";
import { equals } from "ramda";
import footerIcons from "../../assets/home/footer-icons.svg";

import { ConfigurableDragableComponent } from "./wysiwyg-draggable-item";
import { useTranslation } from "react-i18next";

export const WysiwygColumn = ({
  placedComponents,
  droppableId,
  selectedComponentUUID,
  onSelectComponent,
}) => {
  const { t } = useTranslation();
  return (
    <div className="wysiwyg-column">
      <Card className="main-card">
        <div className="speaker"></div>
        <CardHeader
          style={{ borderRadius: "40px 40px 0 0", backgroundColor: "white" }}
        ></CardHeader>
        <CardBody className="components-container">
          <Droppable droppableId={droppableId}>
            {(provided, snapshot) => (
              <div
                className={classnames("droppable", {
                  "dragged-over": snapshot.isDraggingOver, // Style for this was removed
                })}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {placedComponents.map(({ uuid, ...componentItem }, idx) => (
                  <ConfigurableDragableComponent
                    key={`${uuid}_${componentItem.componentId}`}
                    idx={idx}
                    isSelected={equals(selectedComponentUUID, uuid)}
                    uuid={uuid}
                    onSelect={onSelectComponent}
                    {...componentItem}
                  />
                ))}
                {!placedComponents.length && (
                  <div className="phone-empty">{t("home.copyHomeEmpty")}</div>
                )}
              </div>
            )}
          </Droppable>
        </CardBody>
        <CardFooter
          className="footer-icons" /*style={{ borderRadius: "0 0 40px 40px" }}*/
        >
          <img src={footerIcons} />
        </CardFooter>
      </Card>
    </div>
  );
};
