import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "reactstrap";
import { getSavedImages, uploadImage, removeImage } from "../../api";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import { UploadedImages } from "../home/main/configurator/uploaded-images";
import { useTranslation } from "react-i18next";

const buttonLabels = {
  "News Top Image": "SELECT ARTICLE IMAGE",
};

export const UpdateImageModal = ({
  title,
  savedValue,
  label,
  onChange,
  parentOption,
  userType,
  storeKey
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [savedImages, setSavedImages] = useState([]);  

  useEffect(() => {
    async function fetchSavedImages() {
      let response = await getSavedImages(storeKey);
      setSavedImages(response.data);
    }

    fetchSavedImages();
    console.log("saved", savedValue);
    setValue(savedValue);
  }, [savedValue]);

  const saveImage = (value) => {
    onChange(label, value, parentOption);
    setIsOpen(false);
  };

  const onSelect = (image) => {
    setValue(image);
    saveImage(image);
  };

  const onRemove = async (image) => {
    console.log("onRemove", image.name);
    await removeImage({
      name: image.name,
      storeKey: storeKey
    });
  };

const uploadHandler = async (e) => {
  try {
      const data = new FormData();
      data.append("uploadedFileName", e.target.files[0]);
      data.append("storeKey", storeKey); // Append the storeKey to the form data

      console.log("await UPLOADED", data);
      const response = await uploadImage(data);
      
      if (response && response.data) {
          console.log("FILE UPLOADED", response.data);
          console.log("FILE UPLOADED FINAL", response.data.data);
          setSavedImages([...savedImages, response.data.data]);
      } else {
          console.error("Unexpected response:", response);
      }
  } catch (error) {
      console.error("Error during upload:", error);
  }
};

const shouldDisableButton = () => {
  switch (userType) {
    case "trial":
      return true
    default:
      return false;
  }
};

  return (
    <Container>
      <Row>
        {!buttonLabels[title] && (
          <Col
            className="config-label parent-label"
            style={{ paddingBottom: "10px" }}
          >
            {"IMAGE FILE:"}
          </Col>
        )}
        <CustomButton
          type="CONTENT"
          onClick={() => setIsOpen(true)}
          label={buttonLabels[title] || t("homeModalRight.selectImages")}
        />
      </Row>
      <Modal isOpen={isOpen} className="update-image-modal" size="lg3">
        <Container fluid={true}>
          <Row xs="2" className="title-row">
            <Col xs="6" className="modal-title">
            </Col>
            <Col xs="4">
              <div style={{ display: "flex", width: "400px", marginLeft: "50px" }}>
                <label
                  for="file-upload"
                  class="custom-button content-button upload-image"
                  style={{ opacity: shouldDisableButton() ? 0.5 : 1 }}
                >
                  {t("homeModalUploadImage.title")}
                </label>
                <input type="file" id="file-upload" onChange={uploadHandler} disabled={shouldDisableButton()} />
                <CustomButton
                  type="CANCEL"
                  label={t("homeModalUploadImage.buttonCancel")}
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </Col>
          </Row>
          <UploadedImages
            savedImages={savedImages}
            onSelect={onSelect}
            onRemove={onRemove}
            selectedImage={value}
            userType={userType}
          />
        </Container>
      </Modal>
    </Container>
  );
};
