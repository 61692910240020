import React, { useState, useEffect } from "react";
import Background from "../img/phone.png";
import Image from "../common/img";
import ModalProducts from "./modalProducts";
import ModalCollections from "./modalCollections";
// Nuevo
import { Modal, Container, Row, Col } from "reactstrap";
import { getSavedImages, uploadImage, removeImage } from "../../api";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import { UploadedImages } from "../home/main/configurator/uploaded-images";
import { useTranslation } from "react-i18next";

function ModalNotification({
  close,
  handle,
  isOpen,
  showSaveButton,
  onSendNow,
  storeKey,
  userType
}) {
  const { t } = useTranslation();
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [actionItem, setActionItem] = useState(null);
  const [action, setAction] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [value, setValue] = useState("");
  const [savedImages, setSavedImages] = useState([]);

  useEffect(() => {
    // Check the conditions and update the isDisabled state accordingly
    setIsDisabled(
      notificationTitle.length <= 3 ||
        notificationMessage.length <= 4 ||
        (action !== "gotoHome" && !actionItem)
    );
  }, [notificationTitle, notificationMessage, action, actionItem]);

  useEffect(() => {
    setAction("gotoHome");
    setActionItem(null);
    setValue("");
  }, []);

  useEffect(() => {
    async function fetchSavedImages() {
      let response = await getSavedImages(storeKey);
      setSavedImages(response.data);
    }

    fetchSavedImages();
  }, []);

  const handleOnChangeNotificationTitle = (evt) => {
    setNotificationTitle(evt.target.value);
  };

  const handleOnChangeNotificationMessage = (evt) => {
    setNotificationMessage(evt.target.value);
  };

  const handleOnClose = () => {
    setAction("gotoHome");
    setNotificationTitle("");
    setNotificationMessage("");
    setValue("");
    setActionItem(null);
    close();
  };

  const handleOnSendNow = () => {
    const newNotification = {
      titleCard: notificationTitle,
      paragraphCard: notificationMessage,
      image: value,
      action,
      productId: "",
      collectionId: "",
    };

    if (action === "gotoProduct") {
      newNotification.productId = actionItem.id.toString();
    }

    if (action === "gotoCollection") {
      newNotification.collectionId = actionItem.id.toString();
    }
    onSendNow(newNotification);
    handleOnClose();
  };

  const handleOnApply = (item) => {
    console.log("handleOnApply item", item);
    console.log("handleOnApply product", item.configuration.products[0]);

    const product = item.configuration.products?.[0];
    if (product) {
      setActionItem(product);
    }

    setOpenProductModal(false);
  };

  const handleOnChangeAction = (val) => {
    setAction(val);
    setActionItem(null);
  };

  function capitalizeFirstLetter(sentence) {
    const words = sentence.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  }

  const onSelect = (image) => {
    setOpenImageModal(false);
    setValue(image);
  };

  const onRemove = async (image) => {
    console.log("onRemove", image.name);
    await removeImage({
      name: image.name,
      storeKey: storeKey,
    });
  };

  const uploadHandler = async (e) => {
    try {
      const data = new FormData();
      data.append("uploadedFileName", e.target.files[0]);
      data.append("storeKey", storeKey); // Append the storeKey to the form data

      console.log("await UPLOADED", data);
      const response = await uploadImage(data);

      if (response && response.data) {
        console.log("FILE UPLOADED", response.data);
        console.log("FILE UPLOADED FINAL", response.data.data);
        setSavedImages([...savedImages, response.data.data]);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error during upload:", error);
    }
  };

  const shouldDisableButton = () => {
    switch (userType) {
      case "trial":
        return true
      default:
        return false;
    }
  };

  return (
    <>
      {isOpen && (
        <article className="modal isOpen" onClick={close}>
          <div className="modal__container col" onClick={handle}>
            <div className="header__modal row">
              <div className="col">
                <h2 className="modal__title">{t("tabNotifications.modalTitle")}</h2>
              </div>
              <div className="row">
                <button
                  onClick={handleOnClose}
                  className="buttonComponent default"
                >
                  {t("homeModalProducts.buttonCancel")}
                </button>
                {showSaveButton && (
                  <button onClick={close} className="buttonComponent edit">
                    {t("buttons.save").toUpperCase()}
                  </button>
                )}
              </div>
            </div>
            <div className="body__modal row">
              <div className="info__modal col">
                <div className="notification row">
                <div className="title__notification">                  
  {t("tabNotifications.modalSubTitleMessage")
    .split("<br />")
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < t("tabNotifications.modalSubTitleMessage").split("<br />").length - 1 && <br />}
      </React.Fragment>
    ))}
</div>
                </div>
                <div className="form__modal col">
                  <label className="labelText col">
                  {t("tabNotifications.modalTitleMessage")}
                    <input
                      className="inputComponent --title"
                      type="text"
                      name="titleText"
                      placeholder="Title"
                      value={notificationTitle}
                      onChange={handleOnChangeNotificationTitle}
                    />
                    <textarea
                      className="inputComponent --message"
                      name="textArea"
                      placeholder="Message"
                      form="usrform"
                      value={notificationMessage}
                      onChange={handleOnChangeNotificationMessage}
                    ></textarea>
                  </label>
                  <div className="contentProducts">
                    <label className="labelProduct col">
                    {t("tabNotifications.modalAction")}
                    </label>
                    <div className="actionContainer">
                      <select
                        className="inputComponent --product tagSelect"
                        name="Products"
                        onChange={(e) => handleOnChangeAction(e.target.value)}
                      >
                        <option className="item" value="gotoHome">
                        {t("tabNotifications.modalHomeCopy")}
                        </option>
                        <option className="item" value="gotoProduct">
                        {t("tabNotifications.modalProductCopy")}
                        </option>
                        <option className="item" value="gotoCollection">
                        {t("tabNotifications.modalCollectionCopy")}
                        </option>
                      </select>
                      <input
                        id="select"
                        className={`inputComponent ${
                          action === "gotoHome"
                            ? "--submit-disabled"
                            : "--submit"
                        }`}
                        type="submit"
                        value={t("tabNotifications.modalSelectButton")}
                        onClick={() => setOpenProductModal(true)}
                        disabled={action === "gotoHome"}
                      />
                    </div>
                  </div>
                  {openProductModal && action == "gotoProduct" && (
                    <ModalProducts
                      isOpen={isOpen}
                      onClose={() => setOpenProductModal(false)}
                      onDone={handleOnApply}
                      availableProducts={[]}
                      selectedProducts={actionItem ? [actionItem] : []}
                      isModalNotification={true}
                      selectOneItem={true}
                      storeKey={storeKey}
                    />
                  )}
                  {openProductModal && action == "gotoCollection" && (
                    <ModalCollections
                      isOpen={isOpen}
                      onClose={() => setOpenProductModal(false)}
                      onDone={handleOnApply}
                      availableCategories={[]}
                      selectedCategories={actionItem ? [actionItem] : []}
                      isModalNotification={true}
                      selectOneItem={true}
                      storeKey={storeKey}
                    />
                  )}
                  {openImageModal && (
                    <Modal
                      isOpen={true}
                      className="update-image-modal"
                      size="lg3"
                    >
                      <Container fluid={true}>
                        <Row xs="2" className="title-row">
                          <Col xs="6" className="modal-title"></Col>
                          <Col xs="4">
                            <div
                              style={{
                                display: "flex",
                                width: "400px",
                                marginLeft: "50px",
                              }}
                            >
                              <label
                                for="file-upload"
                                class="custom-button content-button upload-image"
                                style={{ opacity: shouldDisableButton() ? 0.5 : 1 }}
                              >
                                {t("tabNotifications.modalUploadImageCopy")}
                              </label>
                              <input
                                type="file"
                                id="file-upload"
                                onChange={uploadHandler}
                                disabled={shouldDisableButton()} 
                                />
                              <CustomButton
                                type="CANCEL"
                                label={t("homeModalProducts.buttonCancel")}
                                onClick={() => setOpenImageModal(false)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <UploadedImages
                          savedImages={savedImages}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          selectedImage={value}
                          userType={userType}
                        />
                      </Container>
                    </Modal>
                  )}
                </div>
                <div className="contentSend row">
                  <input
                    id="upload"
                    className={`inputComponent --upload`}
                    type="submit"
                    value={t("tabNotifications.modalSelectNotificationButton")}
                    disabled={false}
                    onClick={() => setOpenImageModal(true)}
                  />
                </div>
                <div className="contentSend row">
                  <input
                    id="send"
                    className={`inputComponent ${
                      isDisabled ? "--submit-disabled" : "--submit"
                    }`}
                    type="submit"
                    value= {t("tabNotifications.buttonSendNow")}
                    disabled={isDisabled}
                    onClick={handleOnSendNow}
                  />
                </div>
                <div>
                  {actionItem && (
                    <p className="itemSelectedTitle">
                      {t("tabNotifications.modalSelectedCopy")}
                      {action === "gotoCollection"
                        ? " Collection: "
                        : " Product: "}
                      <span className="itemSelected">
                        {capitalizeFirstLetter(actionItem.name)}
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div
                className="img__modal col"
                style={{ backgroundImage: `url(${Background})` }}
              >
                <picture className="picture__modal">
                  <div className="miniCard">
                    <div className="content__mini">
                      <span className="title__card">{notificationTitle}</span>
                      <span className="paragraph__card">
                        {notificationMessage}
                      </span>
                    </div>
                    <div>
                      {value && (
                        <Image
                          width={49}
                          height={60}
                          image={value}
                          alt="Push Image"
                        />
                      )}
                    </div>
                  </div>
                </picture>
              </div>
            </div>
          </div>
        </article>
      )}
    </>
  );
}

export default ModalNotification;
