import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButton from "../../common/RadioButton";
import BoxText from "../../common/BoxText";
import RadioButtonSlide from "../../common/radioButtonSlide";
import NavBar from "./NavBar";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
  fetchCurrentConfigTheme,
  fetchProductListNavBarData,
  updateProductListNavBarData,
} from "../../../api";

function ProductList_navbar({ storeKey }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState(1);
  const [block, setBlock] = useState("square");
  const [isColor, setIsColor] = useState("#CCCCCC");
  const [isActive, setActive] = useState(true);
  const [color1, setColor1] = useState();
  const [templateID, setTemplateID] = useState();

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  useEffect(() => {
    // This function is run whenever `isActive` changes
    console.log("isActive has changed:", isActive);
    updateColorComponent();
  }, [isActive]); // Dependency array. Only re-run the effect if `isActive` changes.

  const getCurrentConfigTheme = () => {
    setIsLoading(true);

    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

    // API
    fetchProductListNavBarData(storeKey, templateID)
      .then((response) => {
        // Here's the change
        const data = response.data;
        const colorData = data.color;
        const navBarData = data.navBar;

        console.log("colorData", colorData);
        console.log("navBarData", navBarData);
        console.log("navBarData  navBarData.mainColor", navBarData.mainColor);
        console.log("navBarData  status", navBarData.mainColor ? true : false);

        if (colorData) {
          setColor1(colorData.mainColor);
        }
        if (navBarData) {
          setIsSelected(Number(navBarData.theme));
          setBlock(options[navBarData.theme]);
          setIsColor(navBarData.mainColor || "#666666");
          setActive(navBarData.mainColor ? true : false);
        }

        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching navBar data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  const updateConfig = async () => {
    setIsSaving(true);

    // API
    updateProductListNavBarData(
      storeKey,
      templateID,
      isSelected,
      isActive ? isColor : null
    )
      .then((response) => {
        console.log("response", response);
        updateColorComponent();
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateCheckoutBottomData:", error);
      });
  };

  const updateColorComponent = () => {
    console.log("updateColorComponent isActive:", isActive);
    if (isActive == false) {
      setIsColor(color1);
    }
  };

  const checkedChange = (e) => {
    setBlock(e.target.name);
    setIsSelected(Number(e.target.value));
  };

  const colorChange = (e) => {
    setIsColor(e.target.value);
    setActive(true);
  };

  const handleToggle = () => {
    setActive(!isActive);
  };

  const options = [
    "SQUARE",
    "SQUARE TOP LINE",
    "SQUARE  BOTTOM LINE",
    "SQUARE DOUBLE LINE",
    "ROUNDED",
    "FOUR CORNERS",
    "SKEW",
    "PANAL",
    "LINEAR BADGE",
    "ROUNDED BADGE",
    "HALF OVAL",
    "TRAPEZE",
  ];

  return (
    <section className="--navbar productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.loading")}</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.saving")}</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">{t("productListing.headerTitle")}:</h2>
          <nav
            className="componentNav"
            style={{  marginTop: "10px" }}
          >
            <ul className="componentList">
              <li className="itemList">
                <Link to="/lists" style={{ color: "#6D7A8B" }}>
                  HEADER
                </Link>
              </li>
              <li className="itemList  active">
                MENU
                <BoxText handleSaveButton={updateConfig}>
                {t("productListing.menuDescription")}
                </BoxText>
              </li>
              <li className="itemList">
                <Link to="/lists-lists" style={{ color: "#6D7A8B" }}>
                  LISTS
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row">
          <h2 className="title">Menu type:</h2>
          <div className="row settings radios">
            {options?.map((option, index) => {
              return (
                <RadioButton
                  label={option}
                  id={option.toLowerCase()}
                  value={index}
                  onChange={checkedChange}
                  check={isSelected}
                />
              );
            })}
          </div>
        </div>
        <div className="settingsGroup row">
          <h2 className="title">Colors</h2>
          <div
            className="row settings radioCustom"
            style={{ marginTop: "-57px", marginLeft: "135px", width: "70%" }}
          >
            <RadioButtonSlide
              label="CUSTOM COLORS"
              id="customColors"
              value={16}
              isActive={isActive}
              onClick={handleToggle}
            />
            <div className="contentColors">
              <input
                type="text"
                name="textColors"
                className="textColor"
                value={isColor}
                onChange={colorChange}
              />
              <input
                type="color"
                name="colors"
                className="pickColor"
                value={isColor}
                onChange={colorChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <NavBar theme={block.toUpperCase()} color={isColor} />
      </div>
    </section>
  );
}

export default ProductList_navbar;
