/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButton from "../../common/RadioButton";
import BoxText from "../../common/BoxText";

import ReactPlayer from "react-player";
import video1 from "../../videos/08_FadeIn.mp4";
import video2 from "../../videos/09_Mask.mp4";
import video3 from "../../videos/07_AnimateBy4Corners.mp4";
import video4 from "../../videos/06_AnimateHalfVertical.mp4";
import video5 from "../../videos/05_AnimateHalfHorizontal.mp4";
import video6 from "../../videos/03_AnimateByColumnsMovie_Left.mp4";
import video7 from "../../videos/04_AnimateByColumnsMovie_Right.mp4";
import video8 from "../../videos/19_TopLeftSquares_Alpha.mp4";
import video9 from "../../videos/20_TopLeftSquares_Flip.mp4";
import video10 from "../../videos/21_TopLeftSquares_Top.mp4";
import video11 from "../../videos/22_TopLeftSquares_Bottom.mp4";
import video12 from "../../videos/23_TopLeftSquares_Left.mp4";
import video13 from "../../videos/24_TopLeftSquares_Right.mp4";
import video14 from "../../videos/25_TopLeftSquares_RotateRight.mp4";
import video15 from "../../videos/26_TopLeftSquares_RotateLeft.mp4";
import video16 from "../../videos/27_TopLeftSquares_ZoomOut.mp4";
import video17 from "../../videos/28_TopRightSquares_Alpha.mp4";
import video18 from "../../videos/29_TopRightSquares_Flip.mp4";
import video19 from "../../videos/30_TopRightSquares_Top.mp4";
import video20 from "../../videos/31_TopRightSquares_Bottom.mp4";
import video21 from "../../videos/32_TopRightSquares_Left.mp4";
import video22 from "../../videos/33_TopRightSquares_Right.mp4";
import video23 from "../../videos/34_TopRightSquares_RotateRight.mp4";
import video24 from "../../videos/35_TopRightSquares_RotateLeft.mp4";
import video25 from "../../videos/36_TopRightSquares_ZoomOut.mp4";
import video26 from "../../videos/46_BottomLeftSquares_Alpha.mp4";
import video27 from "../../videos/47_BottomLeftSquares_Flip.mp4";
import video28 from "../../videos/48_BottomLeftSquares_Top.mp4";
import video29 from "../../videos/49_BottomLeftSquares_Bottom.mp4";
import video30 from "../../videos/50_BottomLeftSquares_Left.mp4";
import video31 from "../../videos/51_BottomLeftSquares_Right.mp4";
import video32 from "../../videos/52_BottomLeftSquares_RotateRight.mp4";
import video33 from "../../videos/53_BottomLeftSquares_RotateLeft.mp4";
import video34 from "../../videos/37_BottomRightSquares_Alpha.mp4";
import video35 from "../../videos/38_BottomRightSquares_Flip.mp4";
import video36 from "../../videos/39_BottomRightSquares_Top.mp4";
import video37 from "../../videos/40_BottomRightSquares_Bottom.mp4";
import video38 from "../../videos/41_BottomRightSquares_Left.mp4";
import video39 from "../../videos/42_BottomRightSquares_Right.mp4";
import video40 from "../../videos/43_BottomRightSquares_RotateRight.mp4";
import video41 from "../../videos/44_BottomRightSquares_RotateLeft.mp4";
import video42 from "../../videos/45_BottomRightSquares_ZoomOut.mp4";
import video43 from "../../videos/10_LeftToRightSquares_Alpha.mp4";
import video44 from "../../videos/11_LeftToRightSquares_Flip.mp4";
import video45 from "../../videos/12_LeftToRightSquares_Top.mp4";
import video46 from "../../videos/13_LeftToRightSquares_Bottom.mp4";
import video47 from "../../videos/14_LeftToRightSquares_Left.mp4";
import video48 from "../../videos/15_LeftToRightSquares_Right.mp4";
import video49 from "../../videos/16_LeftToRightSquares_RotateRight.mp4";
import video50 from "../../videos/17_LeftToRightSquares_RotateLeft.mp4";
import video51 from "../../videos/18_LeftToRightSquares_ZoomOut.mp4";

import video52 from "../../videos/54_Random_Alpha.mp4";
import video53 from "../../videos/55_Random_Flip.mp4";
import video54 from "../../videos/56_Random_Top.mp4";
import video55 from "../../videos/57_Random_Bottom.mp4";
import video56 from "../../videos/58_Random_Left.mp4";
import video57 from "../../videos/59_Random_Right.mp4";
import video58 from "../../videos/60_Random_RotateRight.mp4";
import video59 from "../../videos/61_Random_RotateLeft.mp4";
import video60 from "../../videos/62_Random_ZoomOut.mp4";
import animations from "./ProductHeaderAnimations.json";

import {
  fetchCurrentConfigTheme,
  fetchProductListHeaderData,
  updateProductListHeaderData,
} from "../../../api";

export const dataObject = [
  { id: "54", name: "FADE IN", isRandom: false, value: video1 },
  { id: "53", name: "MASK", isRandom: false, value: video2 },
  { id: "7", name: "4 CORNERS", isRandom: false, value: video3 },
  { id: "6", name: "HALF VERTICAL", isRandom: false, value: video4 },
  { id: "5", name: "HALF HORIZONTAL", isRandom: false, value: video5 },
  { id: "3", name: "COLUMNS LEFT", isRandom: false, value: video6 },
  { id: "4", name: "COLUMNS RIGHT", isRandom: false, value: video7 },
  { id: "8", name: "LINEAR - ALPHA", isRandom: false, value: video43 },
  { id: "9", name: "LINEAR - FLIP", isRandom: false, value: video44 },
  { id: "10", name: "LINEAR - TOP", isRandom: false, value: video45 },
  { id: "11", name: "LINEAR - BOTTOM", isRandom: false, value: video46 },
  { id: "12", name: "LINEAR - LEFT", isRandom: false, value: video47 },
  { id: "13", name: "LINEAR - RIGHT", isRandom: false, value: video48 },
  { id: "14", name: "LINEAR - ROTATE RIGHT", isRandom: false, value: video49 },
  { id: "15", name: "LINEAR - ROTATE LEFT", isRandom: false, value: video50 },
  { id: "16", name: "LINEAR - ZOOM OUT", isRandom: false, value: video51 },
  { id: "8-true", name: "RANDOM - ALPHA", isRandom: true, value: video52 },
  { id: "9-true", name: "RANDOM - FLIP", isRandom: true, value: video53 },
  { id: "10-true", name: "RANDOM - TOP", isRandom: true, value: video54 },
  { id: "11-true", name: "RANDOM - BOTTOM", isRandom: true, value: video55 },
  { id: "12-true", name: "RANDOM - LEFT", isRandom: true, value: video56 },
  { id: "13-true", name: "RANDOM - RIGHT", isRandom: true, value: video57 },
  {
    id: "14-true",
    name: "RANDOM - ROTATE RIGHT",
    isRandom: true,
    value: video58,
  },
  {
    id: "15-true",
    name: "RANDOM - ROTATE LEFT",
    isRandom: true,
    value: video59,
  },
  { id: "16-true", name: "RANDOM - ZOOM OUT", isRandom: true, value: video60 },
  { id: "17", name: "TOP LEFT - ALPHA", isRandom: false, value: video8 },
  { id: "18", name: "TOP LEFT - FLIP", isRandom: false, value: video9 },
  { id: "19", name: "TOP LEFT - TOP", isRandom: false, value: video10 },
  { id: "20", name: "TOP LEFT - BOTTOM", isRandom: false, value: video11 },
  { id: "21", name: "TOP LEFT - LEFT", isRandom: false, value: video12 },
  { id: "22", name: "TOP LEFT - RIGHT", isRandom: false, value: video13 },
  {
    id: "23",
    name: "TOP LEFT - ROTATE RIGHT",
    isRandom: false,
    value: video14,
  },
  { id: "24", name: "TOP LEFT - ROTATE LEFT", isRandom: false, value: video15 },
  { id: "25", name: "TOP LEFT - ZOOM OUT", isRandom: false, value: video16 },
  { id: "26", name: "TOP RIGHT - ALPHA", isRandom: false, value: video17 },
  { id: "27", name: "TOP RIGHT - FLIP", isRandom: false, value: video18 },
  { id: "28", name: "TOP RIGHT - TOP", isRandom: false, value: video19 },
  { id: "29", name: "TOP RIGHT - BOTTOM", isRandom: false, value: video20 },
  { id: "30", name: "TOP RIGHT - LEFT", isRandom: false, value: video21 },
  { id: "31", name: "TOP RIGHT - RIGHT", isRandom: false, value: video22 },
  {
    id: "32",
    name: "TOP RIGHT - ROTATE RIGHT",
    isRandom: false,
    value: video23,
  },
  {
    id: "33",
    name: "TOP RIGHT - ROTATE LEFT",
    isRandom: false,
    value: video24,
  },
  { id: "34", name: "TOP RIGHT - ZOOM OUT", isRandom: false, value: video25 },
  { id: "44", name: "BOTTOM LEFT - ALPHA", isRandom: false, value: video26 },
  { id: "45", name: "BOTTOM LEFT - FLIP", isRandom: false, value: video27 },
  { id: "46", name: "BOTTOM LEFT - TOP", isRandom: false, value: video28 },
  { id: "47", name: "BOTTOM LEFT - BOTTOM", isRandom: false, value: video29 },
  { id: "48", name: "BOTTOM LEFT - LEFT", isRandom: false, value: video30 },
  { id: "49", name: "BOTTOM LEFT - RIGHT", isRandom: false, value: video31 },
  {
    id: "50",
    name: "BOTTOM LEFT - ROTATE RIGHT",
    isRandom: false,
    value: video32,
  },
  {
    id: "51",
    name: "BOTTOM LEFT - ROTATE LEFT",
    isRandom: false,
    value: video33,
  },
  { id: "35", name: "BOTTOM RIGHT - ALPHA", isRandom: false, value: video34 },
  { id: "36", name: "BOTTOM RIGHT - FLIP", isRandom: false, value: video35 },
  { id: "37", name: "BOTTOM RIGHT - TOP", isRandom: false, value: video36 },
  { id: "38", name: "BOTTOM RIGHT - BOTTOM", isRandom: false, value: video37 },
  { id: "39", name: "BOTTOM RIGHT - LEFT", isRandom: false, value: video38 },
  { id: "40", name: "BOTTOM RIGHT - RIGHT", isRandom: false, value: video39 },
  {
    id: "41",
    name: "BOTTOM RIGHT - ROTATE RIGHT",
    isRandom: false,
    value: video40,
  },
  {
    id: "42",
    name: "BOTTOM RIGHT - ROTATE LEFT",
    isRandom: false,
    value: video41,
  },
  {
    id: "43",
    name: "BOTTOM RIGHT - ZOOM OUT",
    isRandom: false,
    value: video42,
  },
];

const getAnimationByOptionName = (optionName) => {
  const animationFound = animations.find(
    (animation) => animation.name === optionName
  );
  if (animationFound) {
    return {
      type: animationFound.type,
      columns: animationFound.columns,
      rows: animationFound.rows,
      velocity: animationFound.velocity,
      isRandom: animationFound.isRandom,
    };
  } else {
    return {
      type: 0,
      columns: 0,
      rows: 0,
      velocity: 0,
      isRandom: false,
    };
  }
};

const getOptionByAnimationData = (savedData) => {
  const animationFound = animations.find(
    (animation) =>
      animation.type === savedData.type &&
      animation.columns === savedData.columns &&
      animation.rows === savedData.rows &&
      animation.velocity === savedData.velocity &&
      animation.isRandom === savedData.isRandom
  );

  if (animationFound) {
    return animationFound.name;
  }
};

function ProductHeader({ storeKey }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState();
  const [isVideoURL, setVideoURL] = useState();
  const [optionName, setOptionName] = useState();
  const [templateID, setTemplateID] = useState();

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  const getCurrentConfigTheme = () => {
    setIsLoading(true);
    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

     // API
     fetchProductListHeaderData(storeKey, templateID)
     .then((response) => {
       // Here's the change
       const data = response.data;
       const colorData = data.color;
       const listData = data.header;

       console.log("listData", listData);

       if (listData) {
         const optionName = getOptionByAnimationData(listData);
         const savedOption = dataObject.find((listData) => listData.name === optionName);

         if(savedOption) { // Make sure savedOption is not undefined
             setIsSelected(savedOption.id);
             setVideoURL(savedOption.value);
         } else {
             console.warn(`No option found for the name: ${optionName}`);
             // Set some default or fallback state here
         }
       }

       setIsLoading(false); // Set loading state to false after data is loaded
     })
     .catch((error) => {
       console.error("Error fetching cart checkout data:", error);
       setIsLoading(false); // Set loading state to false after data is loaded
     });
};


  const updateConfig = () => {
    setIsSaving(true);

    // API
    updateProductListHeaderData(
      storeKey,
      templateID,
      getAnimationByOptionName(optionName).type,
      getAnimationByOptionName(optionName).columns,
      getAnimationByOptionName(optionName).rows,
      getAnimationByOptionName(optionName).velocity,
      getAnimationByOptionName(optionName).isRandom
    )
      .then((response) => {
        console.log("response", response);
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateCheckoutBottomData:", error);
      });

  };

  const checkedChange = (e) => {
    setIsSelected(e.target.value);

    const found = dataObject.find((obj) => {
      return obj.id === e.target.value;
    });

    setOptionName(found.name);
    setVideoURL(found.value);
  };

  return (
    <section className="--header productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.loading")}</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.saving")}</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">{t("productListing.headerTitle")}:</h2>
          <nav
            className="componentNav"
            style={{  marginTop: "10px" }}
          >
            <ul className="componentList">
              <li className="itemList active">
                HEADER
                <BoxText handleSaveButton={updateConfig}>
                {t("productListing.headerDescription")}
                </BoxText>
              </li>
              <li className="itemList">
                <Link to="/lists-navbar" style={{ color: '#6D7A8B' }}>MENU</Link>
              </li>
              <li className="itemList">
                <Link to="/lists-lists" style={{ color: '#6D7A8B' }}>LISTS</Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row">
          <h2 className="title">Transition type:</h2>
          <div
            className="row settings radios"
            style={{ overflowY: "scroll", height: "475px", marginLeft: "6px" }}
          >
            {dataObject.map((data, idx) => (
              <RadioButton
                label={data.name}
                id={data.name.toLowerCase()}
                key={data.name}
                value={data.id}
                onChange={checkedChange}
                check={isSelected}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <ReactPlayer
          url={isVideoURL}
          width="300px"
          muted
          playing
          style={{ position: "relative", top: "72px" }}
        />
      </div>
    </section>
  );
}

export default ProductHeader;
