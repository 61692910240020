import React, { useState } from "react";
import RadioButton from "../common/RadioButton";
import animations from "../home/product-lists-page/ProductHeaderAnimations.json";
import ReactPlayer from "react-player";

import { Modal, Container, Row, Col, ButtonGroup } from "reactstrap";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";
import { dataObject } from "../home/product-lists-page/ProductHeader";
import { useTranslation } from "react-i18next";



const getOptionByAnimationData = (savedData) => {
  const animationFound = animations.find(
    (animation) =>
      animation.type === savedData.type &&
      animation.columns === savedData.columns &&
      animation.rows === savedData.rows &&
      animation.velocity === savedData.velocity &&
      animation.isRandom === savedData.isRandom
  );

  if (animationFound) {
    return animationFound.name;
  }
};

const ModalImageTransition = ({
  isOpen,
  onClose,
  onApply,
  currentAnimation,
  hasRestrictedAnimation
}) => {
  const { t } = useTranslation();
  const firstAnimation = dataObject[0];
  const current = getOptionByAnimationData(currentAnimation);
  const savedOption = dataObject.find((data) => data.name === current);

  const [isSelected, setIsSelected] = useState(
    savedOption?.id || firstAnimation.id
  );
  const [isVideoURL, setVideoURL] = useState(
    savedOption?.value || firstAnimation.value
  );
  const [optionName, setOptionName] = useState(current || firstAnimation.name);


  const allowedIdsWide = ["54", "6", "3", "4"];
  const allowedIdsDoubleTriple = ["54", "53", "5"];


  let filteredDataObject;
switch(hasRestrictedAnimation) {
  case 'WIDE':
    filteredDataObject = dataObject.filter(data => allowedIdsWide.includes(data.id));
    break;
  case 'DOUBLE_TRIPLE':
    filteredDataObject = dataObject.filter(data => allowedIdsDoubleTriple.includes(data.id));
    break;
  default:
    filteredDataObject = dataObject;
    break;
}


  const checkedChange = (e) => {
    setIsSelected(e.target.value);
    console.log(e.target.value);
    const found = dataObject.find((obj) => {
      return obj.id === e.target.value;
    });

    setOptionName(found.name);
    setVideoURL(found.value);
    console.log(found);
  };

  const getAnimationByOptionName = () => {
    const animationFound = animations.find(
      (animation) => animation.name === optionName
    );
    if (animationFound) {
      return {
        type: animationFound.type,
        columns: animationFound.columns,
        rows: animationFound.rows,
        velocity: animationFound.velocity,
        isRandom: animationFound.isRandom,
      };
    }
  };

  const handleOnApply = () => {
    onApply(getAnimationByOptionName());
  };
  return (
    <Modal isOpen={isOpen} className="update-image-modal" size="lg2">
      <Container fluid={true}>
        <Row xs="2" className="title-row">
          <Col xs="9" className="modal-title">
            <div className="settingsGroup row">
              <h2 className="title">{t("homeModalTransitionImage.title")}:</h2>
            </div>
          </Col>
          <Col xs="2" style={{marginLeft: "20px"}}>
            <ButtonGroup>
              <CustomButton type="CANCEL" label={t("homeModalTransitionImage.buttonCancel")} onClick={onClose} />
              <CustomButton type="SAVE" label={t("homeModalTransitionImage.buttonApply")} onClick={handleOnApply} />
            </ButtonGroup>
          </Col>
        </Row>
        <Row xs="3" className="title-row">
          <div
            className="settings radios"
            style={{
              overflowY: "scroll",
              height: "510px",
              overflowX: "hidden",
            }}
          >
            {filteredDataObject.map((data, idx) => (
              <RadioButton
                label={data.name}
                id={data.name}
                key={data.name}
                value={data.id}
                onChange={checkedChange}
                check={isSelected}
              />
            ))}
          </div>
          <div className="img__edit col" style={{paddingLeft: "20px"}}>
            <ReactPlayer
              url={isVideoURL}
              width="670px"
              height="520px"
              muted
              playing
            />
          </div>
        </Row>
        {}
      </Container>
    </Modal>
  );
};

export default ModalImageTransition;
