/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Background from "../../img/Phone-Collections.png";
import LoadingImage from "../../img/FengLoaderWhitePNG.png";
import RadioButtonSlide from "../../common/radioButtonSlide";
import RadioButtonIcon from "../../common/RadioButtonIcon";
import BoxText from "../../common/BoxText";
import ProductCardBlocks from "./ProductCardBlocks";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  fetchCurrentConfigTheme,
  fetchProductDetailCardData,
  updateProductDetailCardData,
} from "../../../api";


const getBlockFromTheme = (theme, curvedPath) => {
  if (theme == 2) {
    if (curvedPath) {
      return "theme2_top";
    } else {
      return "theme2_bottom";
    }
  }

  if (theme == 1) {
    return "theme1";
  }

  if (theme == 3) {
    return "theme3";
  }

  return "theme2_top";
};

const getGradientValue = (gradientType) => {
  switch (gradientType) {
    case null:
      return "noGradient";
    case 3:
      return "top";
    case 2:
      return "down";
    default:
      return "noGradient";
  }
};

const getGradientType = (gradientValue) => {
  switch (gradientValue) {
    case "noGradient":
      return null;
    case "top":
      return 3;
    case "down":
      return 2;
    default:
      return null;
  }
};

const getTheme = (isSelected) => {
  switch (isSelected) {
    case "1":
      return 1;
    case "2":
      return 2;
    case "3":
      return 3;
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    default:
      return null;
  }
};

function ProductDetails({ storeKey }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSaving, setIsSaving] = useState(false); // Saving state
  const [isSelected, setIsSelected] = useState(1);
  const [block, setBlock] = useState("theme2_top");
  const [isColor, setIsColor] = useState("#666666");
  const [secondaryColor, setSecondaryColor] = useState("#CCCCCC");
  const [isActive, setActive] = useState(true);
  const [curveValue, setCurveValue] = useState("top");
  const [gradientValue, setGradientValue] = useState("noGradient");

  const [color1, setColor1] = useState();
  const [color2, setColor2] = useState();
  const [templateID, setTemplateID] = useState();

  useEffect(() => {
    const storedTemplateID = localStorage.getItem("rootTemplateID");
    if (storedTemplateID) {
      setTemplateID(storedTemplateID);
    } else {
      getCurrentConfigTheme();
    }
  }, []);

  useEffect(() => {
    if (templateID) {
      console.log("templateID updated", templateID);
      fetchData();
    }
  }, [templateID]);

  useEffect(() => {
    // This function is run whenever `isActive` changes
    console.log("isActive has changed:", isActive);
    updateColorComponent();
  }, [isActive]); // Dependency array. Only re-run the effect if `isActive` changes.

  const getCurrentConfigTheme = () => {
    setIsLoading(true);

    // API
    fetchCurrentConfigTheme(storeKey)
      .then((response) => {
        const templateID = response.data;
        if (templateID) {
          console.log("setTemplateID", templateID);
          setTemplateID(templateID);
        } else {
          console.error("Error fetching: No template ID in the response");
        }
      })
      .catch((error) => {
        console.error("Error fetching current Theme:", error);
      });
  };

  const fetchData = () => {
    console.log("fetchData", templateID);

    // API
    fetchProductDetailCardData(storeKey, templateID)
      .then((response) => {
        // Here's the change
        const data = response.data;
        const colorData = data.color;
        const productData = data.product;

        if (colorData) {
          setColor1(colorData.mainColor);
          setColor2(colorData.secondaryColor);
        }
        if (productData) {
          setIsSelected(Number(productData.theme));
          setIsColor(productData.mainColor || colorData.mainColor);
          setSecondaryColor(
            productData.secondaryColor || colorData.secondaryColor
          );
          setBlock(
            getBlockFromTheme(productData.theme, productData.curvedPath)
          );
          setActive(productData.mainColor ? true : false);
          setCurveValue(productData.curvedPath ? "top" : "bottom");
          setGradientValue(getGradientValue(productData.gradientType));
        }

        setIsLoading(false); // Set loading state to false after data is loaded
      })
      .catch((error) => {
        console.error("Error fetching collection data:", error);
        setIsLoading(false); // Set loading state to false after data is loaded
      });
  };

  const updateConfig = async () => {
    setIsSaving(true);

    // API
    updateProductDetailCardData(
      storeKey,
      templateID,
      curveValue === "top" ? true : false,
      getGradientType(gradientValue),
      getTheme(isSelected),
      isActive ? isColor : null,
      isActive ? secondaryColor : null
    )
      .then((response) => {
        console.log("response", response);
        updateColorComponent();
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updateProductDetailCardData:", error);
      });
  };

  const checkedChange = (e) => {
    setBlock(e.target.name);
    setIsSelected(e.target.value);
  };

  const colorChange = (e) => {
    setIsColor(e.target.value);
    setActive(true);
  };

  const secondaryColorChange = (e) => {
    setSecondaryColor(e.target.value);
    setActive(true);
  };

  const updateColorComponent = () => {
    if (isActive == false) {
      setIsColor(color1);
      setSecondaryColor(color2);
    }
  };

  const handleToggle = () => {
    setActive(!isActive);
  };

  const handleCurveChange = (e) => {
    setCurveValue(e.target.value);
    setBlock(`theme2_${e.target.value}`);
  };

  const handleGradientChange = (e) => {
    setGradientValue(e.target.value);
  };

  const getOptions = () => {
    if (block.includes("theme2")) {
      return (
        <select
          name="ContainerCorners"
          id="ContainerCorners"
          className="tagSelect"
          onChange={handleCurveChange}
          value={curveValue}
        >
          <option value="bottom">CURVED DOWN</option>
          <option value="top">CURVED UP</option>
        </select>
      );
    } else {
      return (
        <select
          name="ContainerCorners"
          id="ContainerCorners"
          className="tagSelect"
          onChange={handleGradientChange}
          value={gradientValue}
        >
          <option value="noGradient">NO GRADIENT</option>
          <option value="top">TOP</option>
          <option value="down">DOWN</option>
        </select>
      );
    }
  };


  return (
    <section className="--detailProduct productList row">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.loading")}</div>
        </div>
      )}
      {isSaving && (
        <div className="loading-overlay">
          <div className="loading-content">
            <img src={LoadingImage} alt="Loading" />
          </div>
          <div className="loading-text">{t("common.saving")}</div>
        </div>
      )}
      <div className="info__edit col">
        <div className="titles__page">
          <h2 className="title primary">{t("productDetails.cardTitle")}:</h2>
          <nav
            className="componentNav"
            style={{  marginTop: "10px" }}
          >
            <ul className="componentList">
              <li className="itemList active">
                PRODUCT CARD
                <BoxText handleSaveButton={updateConfig}>
                {t("productDetails.cardDescription")}
                </BoxText>
              </li>
              <li className="itemList">
                <Link to="/add-to-cart" style={{ color: "#6D7A8B" }}>
                  ADD TO CART
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="settingsGroup row">
          <h2 className="title">Themes:</h2>
          <div className="col settings radios">
            <RadioButtonIcon
              label="REGULAR"
              id="theme1"
              value={1}
              onChange={checkedChange}
              check={isSelected}
              title="Product Detail Regular"
              description=""
              video="./sidevideos/productDetailCard1.mp4"
            />
            <RadioButtonIcon
              label="BUBBLES"
              id="theme2_top"
              value={2}
              onChange={checkedChange}
              check={isSelected}
              title="Product Detail Bubbles"
              description=""
              video="./sidevideos/productDetailCard2.mp4"
            />
            <RadioButtonIcon
              label="ANIMATED BUBBLES"
              id="theme3"
              value={3}
              onChange={checkedChange}
              check={isSelected}
              title="Product Detail Animated Bubbles"
              description=""
              video="./sidevideos/productDetailCard3.mp4"
            />
          </div>

          <div className="col settings selects">
            {block !== "theme1" && (
              <div className="selectComponent row">
                <label
                  htmlFor="ContainerCorners"
                  style={{ marginLeft: "70px", marginTop: "-5px" }}
                >
                  {block === "theme3" ? "Gradient" : "Top Layout"}
                </label>
                {getOptions()}
              </div>
            )}
          </div>
        </div>
        {block !== "theme1" && (
          <div className="settingsGroup row">
            <h2 className="title">Colors:</h2>
            <div
              className="row radioCustom --colors"
              style={{ width: block.includes("theme2") ? "610px" : "415px" }}
            >
              <RadioButtonSlide
                label="CUSTOM COLORS"
                id="customColors"
                isActive={isActive}
                onClick={handleToggle}
              />
              <div className="contentColors">
                <input
                  type="text"
                  name="textColors"
                  className="textColor"
                  value={isColor}
                  onChange={colorChange}
                />
                <input
                  type="color"
                  name="colors"
                  className="pickColor"
                  value={isColor}
                  onChange={colorChange}
                />
                {block.includes("theme2") && (
                  <>
                    <input
                      type="text"
                      name="textColors2"
                      className="textColor"
                      value={secondaryColor}
                      onChange={secondaryColorChange}
                      style={{ marginLeft: "15px" }}
                    />
                    <input
                      type="color"
                      name="colors2"
                      className="pickColor"
                      value={secondaryColor}
                      onChange={secondaryColorChange}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="img__edit col-md2"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <ProductCardBlocks
          theme={block}
          color={isColor}
          secondaryColor={secondaryColor}
        />
      </div>
    </section>
  );
}

export default ProductDetails;
