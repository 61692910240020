import React from "react";
import { Modal, Col, ButtonGroup} from "reactstrap";
import { CustomButton } from "../home/main/home-layout-draggable-columns/custom-button";

import WarningIcon from "./items/WarningIcon";
import { useTranslation } from "react-i18next";


export const ModalConfirmRemove = ({ onConfirm, onCancel, isOpen }) => {
  const { t } = useTranslation(); // <-- Ahora está dentro del cuerpo de la función

  return (
    <Modal isOpen={isOpen} className="modal-dialog-centered confirm-remove-modal">
      <div className="remove-modal-header">
        <div>
          <WarningIcon />
        </div>
        <div className="remove-modal-message">
          {t("homeModalDelete.title")}
        </div>
      </div>
      <div className="remove-modal-buttons">
        <Col xs="4" style={{maxWidth: "100%"}} >
          <CustomButton type="CANCEL" onClick={onCancel} label={t("homeModalDelete.buttonCancel")} />
        </Col>
        <Col xs="4" style={{maxWidth: "100%"}} >
          <CustomButton type="REMOVE" onClick={onConfirm} label={t("homeModalDelete.buttonRemove")} />
          {/* Aquí se usa t("common.saving") */}
        </Col>
      </div>
    </Modal>
  );
};
