import React, { useState, useEffect } from "react";
import { Row, Container } from "reactstrap";
import { connect } from "react-redux";
import { ModalCollections } from "../../../../modals/modalCollections";
import {
  getAvailableCategories,
  getSelectedCategories,
  addCategories,
  getSelectedComponentsCurrentConfiguration,
  getComponentCollections,
} from "../../../../../ducks";
import { addMarginTop } from "../../../../utils/utilities";
import { CustomButton } from "../../home-layout-draggable-columns/custom-button";
import { useTranslation } from "react-i18next";

export const UpdateContentSectionBaseCategories = ({
  title,
  availableCategories,
  selectedCategories,
  label,
  onInit,
  currentSelectedConfiguration,
  currentProduct,
  onChange,
  parentOption,
  storeKey
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(0);

  const done = (params) => {

    console.log("params", params);
    console.log("products", params.configuration.products);
    console.log("parentOption", parentOption);
    
    setSelectedItems(params.configuration.products.length);
    onChange(params.label, params.configuration.products, parentOption);
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("currentProduct", currentProduct);
    setSelectedItems(currentProduct ? currentProduct.length : 0);   
  }, [currentProduct]);

// WORK AROUND FOR DUPLICATE BLOCK ITEMS
  const findLabelAndUpdateContent = (dataArray) => {
    console.log("dataArray", dataArray);
  
    // First find 'Update content' as before.
    const updateContentObject = dataArray.find(obj => obj.label === "Update category");
    if (updateContentObject && updateContentObject.products) {
      return updateContentObject.products;
    }
  
    // If not found, then look for 'Image 1', 'Image 2', or 'Image 3'
    for (let i = 1; i <= 3; i++) {
      const imageObject = dataArray.find(obj => obj.label === `Image ${i}`);
    
      // Check if 'Image' exists and it has options
      if (imageObject && imageObject.options) {
        const updateProductObject = imageObject.options.find(obj => obj.label === "Update category");
        if (updateProductObject && updateProductObject.products) {
          return updateProductObject.products;
        }
      }
    }
  
    return null;
  }
  
  // Usage:

  useEffect(() => {
    console.log("ENTRA AL INIt currentSelectedConfiguration", currentSelectedConfiguration);
    
    const products = findLabelAndUpdateContent(currentSelectedConfiguration);
    if (products !== null) {
      console.log("findLabelAndUpdateContent" , products);
      setSelectedItems(products.length);
      selectedCategories = products;
    } else {
      selectedCategories = [];
      console.log("No object with the label 'Update category' found, or the object doesn't have a 'products' property.");
    }

    
  }, [currentSelectedConfiguration]);

  return (
    <Container style={addMarginTop(title)}>
      {console.log("availableCategories", availableCategories)}
      {console.log("selectedCategories", selectedCategories)}
      <Row>
        <CustomButton
          type="CONTENT"
          onClick={() => setIsOpen(true)}
          label={t("homeModalCollections.title").toUpperCase()}          
          counter={true}
          itemsCounter={selectedItems}
        />
      </Row>
      <ModalCollections
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDone={done}
        label={label}
        availableCategories={availableCategories}
        selectedCategories={currentProduct}
        onInit={onInit}
        currentSelectedConfiguration={currentSelectedConfiguration}
        parentOption={parentOption}
        selectOneItem={
          title.includes("Boton") ||
          title.includes("Producto Destacado") ||
          title.includes("News Top Image") ||
          title.includes("News No Image") ||
          title.includes("Image 50") ||
          title.includes("Image 25") ||
          title.includes("Image 75") ||
          title.includes("Image 100") ||
          title.includes("Video")
        }
        storeKey={storeKey}
        >
        {console.log("title",title)}
        </ModalCollections>
    </Container>
  );
};

const mapStateToProps = (state) => {
                        return {
  availableCategories: getAvailableCategories(state),
  selectedCategories: getSelectedCategories(state),
  currentSelectedConfiguration: getSelectedComponentsCurrentConfiguration(
    state
  ),
  currentProduct: getComponentCollections(state)
}
};

const dispatchToProps = {
  onInit: addCategories,
};

export const UpdateContentSectionCategories = connect(
  mapStateToProps,
  dispatchToProps
)(UpdateContentSectionBaseCategories);
