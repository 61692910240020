import axios from "axios";

//const SERVER_URL = "http://localhost:7500/api/v1";
const SERVER_URL = "https://d4br8nev72.execute-api.us-east-1.amazonaws.com/api/v1";

//export const SERVER_IMAGE_URL = "http://localhost:7500";
export const SERVER_IMAGE_URL = "https://d4br8nev72.execute-api.us-east-1.amazonaws.com";

axios.defaults.headers.post["Content-Type"] = "application/json";


////////////
/// HOME ///
////////////

// Add Block/Component
export const insertComponentInConfiguration = ({
  order,
  componentId,
  configuration,
  templateID,
  storeKey,
}) => {

  return axios.post(`${SERVER_URL}/configuration`, {
    order,
    componentId,
    configuration,
    templateID,
    storeKey,
  });
};

// Change Block/Component Order
export const swapComponentConfigurationOrder = ({
  newOrderList,
  templateID,
  storeKey,
}) => {

  axios.put(`${SERVER_URL}/configuration/swap_order`, {
    newOrderList,
    templateID,
    storeKey,
  });
};

// Delete Block/Component
export const deleteComponentConfiguration = ({ id, templateID, storeKey }) => {

  return axios.delete(
    `${SERVER_URL}/configuration/${id}?templateID=${templateID}&storeKey=${storeKey}`
  );
};

// Update Block/Component Configuration
export const updateComponentConfiguration = ({
  id,
  configuration,
  templateID,
  storeKey,
}) => {

  return axios.post(`${SERVER_URL}/configuration/${id}`, {
    templateID,
    storeKey,
    configuration,
  });
};

// Fetch Home Components
export const fetchAvailableComponents = async ({ templateID, storeKey }) => {
  const timestamp = new Date().getTime();
  console.log("fetchAvailableComponents api.js templateID", templateID);
  console.log("fetchAvailableComponents api.js storeKey", storeKey);
  return axios
    .post(`${SERVER_URL}/components?_=${timestamp}`, {
      templateID: templateID,
      storeKey: storeKey,
    })
    .then((response) => {
      //console.log('fetchAvailableComponents response:', response.data);
      return response;
    })
    .catch((error) => {
      //console.error('Error in fetchAvailableComponents:', error);
      throw error;
    });
};

// Fetch User Components Configuration
export const fetchForUserComponentsConfiguration = async ({ templateID, storeKey }) => {
  const timestamp = new Date().getTime();
  console.log("fetchForUserComponentsConfiguration api.js storeKey", storeKey);
  return axios
    .post(`${SERVER_URL}/getUserConfiguration?_=${timestamp}`, {
      templateID: templateID,
      storeKey: storeKey,
    })
    .then((response) => {
      console.log('fetchForUserComponentsConfiguration response:', response.data);
      return response;
    })
    .catch((error) => {
      console.error('Error in fetchForUserComponentsConfiguration:', error);
      throw error;
    });
};

// Fetch Categories
export const fetchForCategories = async ({ storeKey }) => {
  const timestamp = new Date().getTime();
  return axios
    .post(`${SERVER_URL}/categories?_=${timestamp}`, {
      storeKey: storeKey,
    })
    .then((response) => {
      //console.log('Response:', response);
      return response;
    })
    .catch((error) => {
      //console.log('catch Error:', error);
      throw error;
    });
};

// Fetch Feng Categories
export const fetchForFengCategories = async ({ storeKey, onlyRoot = false }) => {
  const timestamp = new Date().getTime();
  console.log("fetchForCategories onlyRoot", onlyRoot);
  return axios
    .post(`${SERVER_URL}/fengCategories?_=${timestamp}`, {
      storeKey: storeKey,
      onlyRoot: onlyRoot
    })
    .then((response) => {
      //console.log('Response:', response);
      return response;
    })
    .catch((error) => {
      //console.log('catch Error:', error);
      throw error;
    });
};

// Save Feng Categories
export const saveFengCategories = async ( storeKey, data) => {
  console.log("saveFengCategories storeKey", storeKey);
  console.log("saveFengCategories data", data);
  return axios
    .post(`${SERVER_URL}/saveFengCategories`, {
      storeKey: storeKey,
      data: data,
    })
    .then((response) => {
      console.log('Response:', response);
      return response;
    })
    .catch((error) => {
      console.log('catch Error:', error);
      throw error;
    });
};

// Fetch All Products
export const fetchForAllProducts = ({ storeKey }) => {
  const timestamp = new Date().getTime();
  return axios.post(`${SERVER_URL}/products?_=${timestamp}`, {
    storeKey: storeKey,
  });
};

// Fetch Products by Cat ID
export const fetchForProductsInCategories = (categories) =>
  axios.get(
    `${SERVER_URL}/products?${categories.map((id) => `catId=${id}`).join("&")}`
  );

  // PUBLISH
export const fetchPublishData = (storeKey) =>
axios.get(
  `${SERVER_URL}/getPublishData?storeKey=${storeKey}`
);

// PUBLISH - SAVE
export const updatePublishData = (
  storeKey,
  appIconImage,
  businessInfoMarketingUrl,
  businessInfoPrivacyUrl,
  businessInfoSupportUrl,
  launchScreenImage,
  listingDescription,
  listingKeywords,
  listingPrimaryCategory,
  listingSecondaryCategory,
  listingSubTitle,
  listingTitle
) => {
  console.log("appIconImage", appIconImage);
  console.log("businessInfoMarketingUrl", businessInfoMarketingUrl);
  console.log("businessInfoPrivacyUrl", businessInfoPrivacyUrl);
  console.log("businessInfoSupportUrl", businessInfoSupportUrl);
  console.log("launchScreenImage", launchScreenImage);
  console.log("listingDescription", listingDescription);

  console.log("listingKeywords", listingKeywords);
  console.log("listingPrimaryCategory", listingPrimaryCategory);
  console.log("listingSecondaryCategory", listingSecondaryCategory);
  console.log("listingSubTitle", listingSubTitle);
  console.log("listingTitle", listingTitle);

  return axios.post(`${SERVER_URL}/updatePublishData`, {
    storeKey: storeKey,
    appIconImage, appIconImage,
    businessInfoMarketingUrl: businessInfoMarketingUrl,
    businessInfoPrivacyUrl: businessInfoPrivacyUrl,
    businessInfoSupportUrl: businessInfoSupportUrl,
    launchScreenImage: launchScreenImage,
    listingDescription: listingDescription,
    listingKeywords: listingKeywords,
    listingPrimaryCategory: listingPrimaryCategory,
    listingSecondaryCategory: listingSecondaryCategory,
    listingSubTitle: listingSubTitle,
    listingTitle: listingTitle,
  });
};

/////////////////////
/// NOTIFICATIONS ///
/////////////////////

// Fetch Notifications
export const fetchNotifications = (storeKey) =>
  axios.get(`${SERVER_URL}/getNotifications?storeKey=${storeKey}`);

// Send Push Notification Message
export const sendNotificationMessage = ({
  title,
  body,
  image,
  action,
  productId,
  collectionId,
  storeKey,
}) =>
  axios.post(`${SERVER_URL}/sendNotification`, {
    title,
    body,
    image,
    action,
    productId,
    collectionId,
    storeKey,
    timestamp: new Date().toISOString()
  });

/////////////////////
///    HOME V2    ///
////////////////////

// Fetch Current Theme
export const fetchCurrentConfigTheme = (storeKey) => {
  const timestamp = new Date().getTime();
  return axios
    .get(`${SERVER_URL}/getCurrentConfigTheme?storeKey=${storeKey}&_=${timestamp}`)
    .then((response) => {
      console.log("response", response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      throw error; // Rethrow the error so it can be handled later if needed
    });
};

// Fetch Current Theme Color
export const fetchCurrentColor = (storeKey, templateID) =>
  axios
    .get(
      `${SERVER_URL}/getCurrentColor?storeKey=${storeKey}&templateID=${templateID}`
    )
    .then((response) => {
      console.log("fetchCurrentColor response: ", response);
      return response;
    })
    .catch((err) => {
      console.log("fetchCurrentColor error: ", err);
      throw err;
    });

// COLLECTION LIST - GET
export const fetchCollectionData = (storeKey, templateID) => {
  const timestamp = new Date().getTime();
  return axios.get(
    `${SERVER_URL}/getCollectionData?storeKey=${storeKey}&templateID=${templateID}&_=${timestamp}`
  );
};

  // COLLECTION LIST - SAVE
  export const updateCollectionData = (
    storeKey,
    templateID,
    isRounded,
    imageRounded,
    aligment,
    mainColor,
    theme,
  ) => {
    console.log("updateCollectionData storeKey", storeKey);
    console.log("updateCollectionData isRounded", isRounded);
    console.log("updateCollectionData imageRounded", imageRounded);
    console.log("updateCollectionData aligment", aligment);
    console.log("updateCollectionData mainColor", mainColor);
    console.log("updateCollectionData theme", theme);

    return axios.post(`${SERVER_URL}/updateCollection`, {
      storeKey: storeKey,
      templateID, templateID,
      isRounded: isRounded,
      imageRounded: imageRounded,
      aligment: aligment,
      mainColor: mainColor,
      theme: theme,
    });
  };


// PRODUCT LIST - HEADER - GET
export const fetchProductListHeaderData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getProductListHeaderData?storeKey=${storeKey}&templateID=${templateID}`
  );

// PRODUCT LIST - HEADER - SAVE
export const updateProductListHeaderData = (
  storeKey,
  templateID,
  type,
  columns,
  rows,
  velocity,
  isRandom
) => {
  console.log("updateProductListHeaderData storeKey", storeKey);
  console.log("updateProductListHeaderData type", type);
  console.log("updateProductListHeaderData columns", columns);
  console.log("updateProductListHeaderData rows", rows);
  console.log("updateProductListHeaderData velocity", velocity);
  console.log("updateProductListHeaderData isRandom", isRandom);

  return axios.post(`${SERVER_URL}/updateProductListHeaderData`, {
    storeKey: storeKey,
    templateID, templateID,
    type: type,
    columns: columns,
    rows: rows,
    velocity: velocity,
    isRandom: isRandom
  });
};



// PRODUCT LIST - NAVBAR - GET
export const fetchProductListNavBarData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getProductListNavBarData?storeKey=${storeKey}&templateID=${templateID}`
  );

// PRODUCT LIST - NAVBAR - SAVE
export const updateProductListNavBarData = (
  storeKey,
  templateID,
  theme,
  mainColor
) => {
  console.log("updateProductListNavBarData storeKey", storeKey);
  console.log("updateProductListNavBarData theme", theme);
  console.log("updateProductListNavBarData mainColor", mainColor);

  return axios.post(`${SERVER_URL}/updateProductListNavBarData`, {
    storeKey: storeKey,
    templateID, templateID,
    theme: theme,
    mainColor: mainColor
  });
};
// PRODUCT LIST - LISTS - GET
export const fetchProductListListData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getProductListListData?storeKey=${storeKey}&templateID=${templateID}`
  );

// PRODUCT LIST - LISTS - SAVE
export const updateProductListListData = (
  storeKey,
  templateID,
  listData
) => {
  console.log("updateProductListListData storeKey", storeKey);
  console.log("updateProductListListData listData", listData);

  return axios.post(`${SERVER_URL}/updateProductListListData`, {
    storeKey: storeKey,
    templateID, templateID,
    listData: listData
  });
};

// PRODUCT DETAIL - PRODUCT CARD - GET
export const fetchProductDetailCardData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getProductDetailCardData?storeKey=${storeKey}&templateID=${templateID}`
  );

// PRODUCT DETAIL - PRODUCT CARD - SAVE
export const updateProductDetailCardData = (
  storeKey,
  templateID,
  curvedPath,
  gradientType,
  theme,
  mainColor,
  secondaryColor
) => {
  console.log("updateProductDetailCardData storeKey", storeKey);
  console.log("updateProductDetailCardData curvedPath", curvedPath);
  console.log("updateProductDetailCardData gradientType", gradientType);
  console.log("updateProductDetailCardData theme", theme);
  console.log("updateProductDetailCardData mainColor", mainColor);
  console.log("updateProductDetailCardData secondaryColor", secondaryColor);

  return axios.post(`${SERVER_URL}/updateProductDetailCardData`, {
    storeKey: storeKey,
    templateID, templateID,
    curvedPath: curvedPath,
    gradientType: gradientType,
    theme: theme,
    mainColor: mainColor,
    secondaryColor: secondaryColor
  });
};

// PRODUCT DETAIL - ADD TO CART - GET
export const fetchProductDetailAddCartData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getProductDetailAddCartData?storeKey=${storeKey}&templateID=${templateID}`
  );

// PRODUCT DETAIL - ADD TO CART - SAVE
export const updateProductDetailAddCartData = (
  storeKey,
  templateID,
  checkOutButtonTheme,
  checkOutColor,
) => {
  console.log("updateProductDetailAddCartData storeKey", storeKey);
  console.log("updateProductDetailAddCartData checkOutButtonTheme", checkOutButtonTheme);
  console.log("updateProductDetailAddCartData checkOutColor", checkOutColor);

  return axios.post(`${SERVER_URL}/updateProductDetailAddCartData`, {
    storeKey: storeKey,
    templateID, templateID,
    checkOutButtonTheme: checkOutButtonTheme,
    checkOutColor: checkOutColor
  });
};

// CART CHECKOUT - LIST STYLE - GET
export const fetchCheckoutListData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getCheckoutListData?storeKey=${storeKey}&templateID=${templateID}`
  );

// CART CHECKOUT - LIST STYLE - SAVE
export const updateCheckoutListData = (
  storeKey,
  templateID,
  theme
) => {
  console.log("updateCheckoutListData storeKey", storeKey);
  console.log("updateCheckoutListData templateID", templateID);
  console.log("updateCheckoutListData theme", theme);

  return axios.post(`${SERVER_URL}/updateCheckoutListData`, {
    storeKey: storeKey,
    templateID, templateID,
    theme: theme
  });
};

// CART CHECKOUT - QUANTITY STEPPER - GET
export const fetchCheckoutStepperData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getCheckoutStepperData?storeKey=${storeKey}&templateID=${templateID}`
  );

// CART CHECKOUT - QUANTITY STEPPER - SAVE
export const updateCheckoutStepperData = (
  storeKey,
  templateID,
  theme,
  color
) => {
  console.log("updateCheckoutStepperData storeKey", storeKey);
  console.log("updateCheckoutStepperData theme", theme);
  console.log("updateCheckoutStepperData color", color);

  return axios.post(`${SERVER_URL}/updateCheckoutStepperData`, {
    storeKey: storeKey,
    templateID, templateID,
    theme: theme,
    color: color
  });
};

// CART CHECKOUT - BOTTOM - GET
export const fetchCheckoutBottomData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getCheckoutBottomData?storeKey=${storeKey}&templateID=${templateID}`
  );

// CART CHECKOUT - BOTTOM - SAVE
export const updateCheckoutBottomData = (
  storeKey,
  templateID,
  buttonIsRounded,
  curvedPath,
  color
) => {
  console.log("updateCheckoutBottomData storeKey", storeKey);
  console.log("updateCheckoutBottomData checkOutButtonTheme", buttonIsRounded);
  console.log("updateCheckoutBottomData curvedPath", curvedPath);
  console.log("updateCheckoutBottomData color", color);

  return axios.post(`${SERVER_URL}/updateCheckoutBottomData`, {
    storeKey: storeKey,
    templateID, templateID,
    buttonIsRounded: buttonIsRounded,
    curvedPath: curvedPath,
    color: color
  });
};

// GLOBAL STYLE - GLOBAL COLORS - GET
export const fetchGlobalColorData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getGlobalColorData?storeKey=${storeKey}&templateID=${templateID}`
  );

// GLOBAL STYLE - GLOBAL COLORS - SAVE
export const updateGlobalColorData = (
  storeKey,
  templateID,
  mainColor,
  secondaryColor
) => {
  console.log("updateGlobalColorData storeKey", storeKey);
  console.log("updateGlobalColorData mainColor", mainColor);
  console.log("updateGlobalColorData secondaryColor", secondaryColor);

  return axios.post(`${SERVER_URL}/updateGlobalColorData`, {
    storeKey: storeKey,
    templateID, templateID,
    mainColor: mainColor,
    secondaryColor: secondaryColor
  });
};


// GLOBAL STYLE - LOADERS - GET
export const fetchGlobalLoaderData = (storeKey, templateID) =>
  axios.get(
    `${SERVER_URL}/getGlobalLoaderData?storeKey=${storeKey}&templateID=${templateID}`
  );


// GLOBAL STYLE - LOADERS - SAVE LOADER REGULAR
export const updateGlobalLoaderData = (
  storeKey,
  templateID,
  lottieCustomIndex
) => {
  console.log("updateGlobalLoaderData storeKey", storeKey);
  console.log("updateGlobalLoaderData lottieCustomIndex", lottieCustomIndex);

  return axios.post(`${SERVER_URL}/updateGlobalLoaderData`, {
    storeKey: storeKey,
    templateID, templateID,
    lottieCustomIndex: lottieCustomIndex,
  });
};

// GLOBAL STYLE - LOADERS - ADD LOTTIE
export const addGlobalLottieLoader = (
  storeKey,
  templateID,
  lottieCustomIndex,
  customLoader,
  customLoaderName
) => {
  console.log("updateGlobalLoaderData storeKey", storeKey);
  console.log("updateGlobalLoaderData lottieCustomIndex", lottieCustomIndex);
  console.log("updateGlobalLoaderData customLoader", customLoader);
  console.log("updateGlobalLoaderData customLoaderName", customLoaderName);
  
  return axios.post(`${SERVER_URL}/addGlobalLottieLoader`, {
    storeKey: storeKey,
    templateID, templateID,
    lottieCustomIndex: lottieCustomIndex,
    customLoader: customLoader,
    customLoaderName: customLoaderName
  });
};



/////////////////////
///    THEMES    ///
////////////////////


// THEMES - GET CURRENT THEME
export const getCurrentThemeRequest = ({ storeKey }) => {
  const timestamp = new Date().getTime();
  console.log("getCurrentThemeRequest parameters: ", {
    storeKey,
  });

  return axios.post(`${SERVER_URL}/getCurrentTheme?_=${timestamp}`, {
    storeKey,
  });
};

// THEMES - GET THEME LIST
export const fetchThemes = (storeKey) => {
  const timestamp = new Date().getTime();
  return axios.get(`${SERVER_URL}/getThemes?storeKey=${storeKey}&_=${timestamp}`);
};

// THEMES - DELETE THEME
export const deleteTheme = (storeKey, templateID) => {
  console.log("deleteTheme storeKey: ", storeKey);
  console.log("deleteTheme templateID: ", templateID);

  return axios.delete(
    `${SERVER_URL}/themes/?templateID=${templateID}&storeKey=${storeKey}`
  );
};

// THEMES - RENAME THEME
export const renameTheme = (storeKey, templateID, newName) => {
  console.log("renameTheme parameters: ", {
    storeKey, templateID, newName
  });

  return axios.post(`${SERVER_URL}/renameTheme`, {
    storeKey,templateID, newName
  });
};

// THEMES - DUPLICATE THEME
export const duplicateTheme = (storeKey, templateID, newName) => {
  console.log("duplicateTheme parameters: ", {
    storeKey, templateID, newName
  });

  return axios.post(`${SERVER_URL}/duplicateTheme`, {
    storeKey,templateID, newName
  });
};


// THEMES - ADD NEW THEME
export const addNewTheme = (storeKey, newName, configuration) => {
  console.log("addNewTheme parameters: ", {
    storeKey, newName, configuration
  });

  return axios.post(`${SERVER_URL}/addNewTheme`, {
    storeKey, newName, configuration
  });
};


// THEMES - ADD NEW THEME
export const setCurrentTheme = (storeKey, templateID) => {
  console.log("setCurrentTheme parameters: ", {
    storeKey, templateID
  });

  return axios.post(`${SERVER_URL}/setCurrentTheme`, {
    storeKey, templateID
  });
};


// THEMES - PUBLISH THEME TO PRODUCTION
export const publishCurrentTheme = (storeKey, templateID) => {
  console.log("publishCurrentTheme parameters: ", {
    storeKey, templateID
  });

  return axios.post(`${SERVER_URL}/publishCurrentTheme`, {
    storeKey, templateID
  });
};


/////////////////////////
/// QR CODE / PREVIEW ///
////////////////////////

// Create QR Code for phone preview
export const createQRcode = (storeKey, templateID) => {
  console.log("createQRcode parameters: ", {
    storeKey, templateID
  });

  return axios.post(`${SERVER_URL}/createQRcode`, {
    storeKey, templateID
  });
};


/////////////////////
///    IMAGES    ///
////////////////////

// IMAGE - Upload
export const uploadImage = (file) => {
    return axios.post(`${SERVER_URL}/images/upload`, file);
};

// IMAGE - Read
export const getSavedImages = (storeKey) => axios.get(`${SERVER_URL}/images?storeKey=${storeKey}`);

// IMAGE - Remove
export const removeImage = ({ name, storeKey }) =>
  axios.delete(`${SERVER_URL}/images`, {
    params: {
      name,
      storeKey
    }
  });

/////////////////////
///    VIDEOS    ///
////////////////////

// IMAGE - Upload
export const uploadVideo = (file) => {
  return axios.post(`${SERVER_URL}/videos/upload`, file);
};

// IMAGE - Read
export const getSavedVideos = (storeKey) => axios.get(`${SERVER_URL}/videos?storeKey=${storeKey}`);

// VIDEO - Remove
export const removeVideo = ({ name, storeKey }) =>
  axios.delete(`${SERVER_URL}/videos`, {
    params: {
      name,
      storeKey
    }
  });