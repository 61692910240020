import React, { useState } from "react";
import { Row, Button } from "reactstrap";
import { ModalConfirmRemove } from "../../../modals";
import { CustomButton } from "../home-layout-draggable-columns/custom-button";
import { useTranslation } from "react-i18next";



export const RemoveButton = ({ onRemove }) => {
  const { t } = useTranslation();
  return (
    <CustomButton type="REMOVE" icon={true} onClick={onRemove} label={t("buttons.remove")} />
  );
};

export const RemoveButtonRow = ({ onRemove }) => (
  <Row className="remove-button justify-content-center">
    <RemoveButton onRemove={onRemove} />
  </Row>
);

export const RemoveWithConfirmRow = ({ onRemove }) => {
  const [isConfirmOpen, setIsOpen] = useState(false);
  return (
    <Row className="remove-button justify-content-left">
      <RemoveButton onRemove={() => setIsOpen(true)} />
      <ModalConfirmRemove
        isOpen={isConfirmOpen}
        onConfirm={() => {
          onRemove();
          setIsOpen(false);
        }}
        onCancel={() => setIsOpen(false)}
      />
    </Row>
  );
};
